<ng-container *ngIf="fields.$ | async; let $">
  <div class="title"
    #title>
    <ng-content select=[title]>
    </ng-content>
  </div>
  <div class="search"
    #search>
    <ng-content select=[search]>
    </ng-content>
  </div>
  <div class="actions"
    #actions>
    <ng-content select=[actions]>
    </ng-content>
  </div>
</ng-container>