import { Injectable } from '@angular/core';
import { GuardsCheckEnd, GuardsCheckStart, NavigationEnd, ResolveStart, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { shellState } from 'shared/store/shell.state';

@Injectable({ providedIn: 'root' })
export class ResolverMonitorService {

  params = {} as { [key: string]: string };

  constructor(
    router: Router,
  ) {
    router.events.pipe(
      tap(e => {
        if (e instanceof GuardsCheckStart) {
          this.params = e.state.root.getParams();
        }
        if (e instanceof ResolveStart) {
          shellState.dispatch.resolvingUrl(__filename, e.url);
        }
        if (e instanceof NavigationEnd) {
          shellState.dispatch.resolvingUrl(__filename, '');
          if (shellState.state.disableDefaultLoader) {
            shellState.dispatch.disableDefaultLoader(__filename, false);
          }
        }
      }),
    ).subscribe();
  }

}
