import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest, from, fromEventPattern, Observable, of, Subscription } from 'rxjs';
import { concatMap, debounceTime, delay, filter, finalize, map, startWith, takeWhile, tap } from 'rxjs/operators';
import { manageFields } from 'shared/utils/component.utils';
import { MaterialFormControlBoilerplate } from 'shared/utils/material-control-base';
import { catchErrorThenStop, pipe, takeWhileTruthy } from 'shared/utils/rxjs.utils';
import { isDefined } from 'shared/utils/strict-null-check.utils';

import { MediaDialogComponent, MediaDialogComponentArgs } from '../media-dialog/media-dialog.component';
import { MediaLoaderComponent } from '../media-loader/media-loader.component';
import {
  fileIsAcceptable,
  fileIsAnImage,
  getFileInputAcceptAttributeValue,
  getIcon,
  reduceFileSizeIfItIsAnImage,
} from './media.functions';
import { MediaService } from './media.service';
import { MetaResponse, Modes, MultiFileProps, TypeofFileAcceptTypes } from './media.shapes';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'img[app-media]',
  template: '',
  styleUrls: ['./media.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
  providers: [HttpClient, MediaService],
})
export class MediaComponent extends MaterialFormControlBoilerplate<string | null> implements OnInit, OnDestroy {

  @Input()
  placeholder!: string;
  @Input()
  placeholderTemplate!: TemplateRef<HTMLElement> | { elementRef: ElementRef };
  @Input()
  fileAcceptTypes: TypeofFileAcceptTypes = 'image';
  @Input()
  isGlobal = false;
  @Input()
  multiFileProps!: MultiFileProps;
  @Input()
  src!: null | string;
  @Input()
  showLoader = false;
  @Input()
  bypassTransforms = false;
  @Input()
  mode: Modes = 'thumbnail';
  subscription: Subscription;
  meta!: MetaResponse;
  wrapperElement!: HTMLElement;
  loaderElement!: HTMLElement;
  placeholderElement!: HTMLElement;
  value: null | string = null;
  initialized = false;
  readonly effects$ = combineLatest([
    this.observeSrcInput(),
    this.observeModeInput(),
    this.observeValueChange(),
    this.observeClickEvents(),
    this.observeDragOverEvents(),
    this.observeDropEvents(),
    this.observeResizeEvents(),
  ]);
  readonly fields = manageFields<MediaComponent>(this);

  constructor(
    @Optional() @Self()
    readonly ngControl: NgControl,
    readonly elementRef: ElementRef<HTMLImageElement>,
    readonly matDialog: MatDialog,
    readonly mediaService: MediaService,
    readonly matSnackbar: MatSnackBar,
    readonly viewContainerRef: ViewContainerRef,
  ) {
    super();
    if (this.ngControl) { this.ngControl.valueAccessor = this; }
    this.subscription = this.fields.$.subscribe();
    this.unsetInitialSrcValue();
  }

  ngOnInit() {
    this.placeImageInWrapperDiv();
    this.addLoaderToComponent();
    this.addNgTemplatePlaceholder();
    setTimeout(() => setTimeout(() => {
      if (!this.value && (this.placeholder || this.placeholderTemplate)) {
        this.initialized = true;
        this.setImagePlaceholder();
      }
    }));
  }

  ngOnDestroy() {
    if (this.wrapperElement) {
      this.wrapperElement.parentElement?.removeChild(this.wrapperElement);
    }
    this.subscription.unsubscribe();
  }

  private observeResizeEvents() {
    return pipe(
      concatMap(() => new Observable(subscriber => {
        const ro = new ResizeObserver(entries => subscriber.next(entries));
        ro.observe(this.elementRef.nativeElement);
        return () => ro.disconnect();
      })),
      filter(() => !!this.value),
      concatMap(() => this.downloadMediaAndUpdateImage()),
    );
  }

  private observeSrcInput() {
    return pipe(
      concatMap(() => this.fields.src),
      delay(0),
      tap(src => this.writeValue(src)),
    );
  }

  private observeModeInput() {
    return pipe(
      concatMap(() => this.fields.mode),
      filter(mode => mode !== 'thumbnail'),
      tap(() => this.elementRef.nativeElement.style.cursor = 'pointer'),
    );
  }

  private observeValueChange() {
    return pipe(
      concatMap(() => combineLatest([
        this.fields.value,
        this.fields.placeholder.pipe(startWith(null)),
        this.fields.placeholderTemplate.pipe(startWith(null)),
      ])),
      debounceTime(0),
      concatMap(([value, placeholder, placeholderTemplate]) => {
        if (value) {
          return this.downloadMediaAndUpdateImage();
        } else if (this.initialized && (placeholder || placeholderTemplate)) {
          this.setImagePlaceholder();
        }
        return of({});
      })
    );
  }

  private handleApiError() {
    this.setImagePlaceholder();
    return of(null);
  }

  private updateLoader(show: boolean) {
    if (!this.loaderElement) { return; }
    this.loaderElement.style.opacity = show ? '1' : '0';
  }

  private updatePlaceholderTemplate(show: boolean) {
    if (!this.placeholderElement) { return; }
    this.placeholderElement.style.opacity = show ? '1' : '0';
  }

  private setImagePlaceholder() {
    if (this.placeholderTemplate) {
      // set timeout because placeholder template may not have been defined yet.
      setTimeout(() => this.updatePlaceholderTemplate(true));
    } else {
      this.elementRef.nativeElement.src = this.placeholder;
      this.elementRef.nativeElement.style.opacity = '1';
    }
  }

  private unsetInitialSrcValue() {
    const src = this.elementRef.nativeElement.getAttribute('src');
    if (src) {
      this.elementRef.nativeElement.src = '';
    }
  }

  private observeClickEvents() {
    return pipe(
      concatMap(() => fromEventPattern<PointerEvent>(
        handler => this.elementRef.nativeElement.addEventListener('click', handler, false),
        (handler, listener) => this.elementRef.nativeElement.removeEventListener('click', listener),
      )),
      filter(() => this.mode !== 'thumbnail'),
      tap(() => {
        if (!this.meta) {
          this.openFileDialog();
        } else {
          this.openMediaDialog();
        }
      })
    );
  }

  private observeDragOverEvents() {
    return pipe(
      concatMap(() => fromEventPattern<DragEvent>(
        handler => this.elementRef.nativeElement.addEventListener('dragover', handler, false),
        (handler, listener) => this.elementRef.nativeElement.removeEventListener('dragover', listener),
      )),
      tap(ev => {
        ev.preventDefault();
        isDefined(ev.dataTransfer).dropEffect = this.mode === 'replaceable' ? 'move' : 'none';
      })
    );
  }

  private observeDropEvents() {
    return pipe(
      concatMap(() => fromEventPattern<DragEvent>(
        handler => this.elementRef.nativeElement.addEventListener('drop', handler, false),
        (handler, listener) => this.elementRef.nativeElement.removeEventListener('drop', listener),
      )),
      map(ev => {
        ev.preventDefault();
        return isDefined(ev.dataTransfer).files[0];
      }),
      takeWhile(file => fileIsAcceptable(file, this.fileAcceptTypes, this.matSnackbar)),
      tap(() => this.updateLoader(true)),
      concatMap(file => this.uploadFile(file)),
      tap(() => {
        const { hasMultipleFiles, multiFileIndex } = this.getMultiFileInfo();
        if (hasMultipleFiles) {
          this.multiFileProps.onChange({ ...this.meta, index: multiFileIndex });
        }
      }),
    );
  }

  private getMultiFileInfo() {
    const mediaGroup = this.elementRef.nativeElement.closest('app-media-group');
    const hasMultipleFiles = !!mediaGroup && Array.from(mediaGroup.querySelectorAll('img'))
      .filter(e => e.tagName === 'IMG').length > 1;
    const multiFileIndex = !mediaGroup ? 0 : Array.from(mediaGroup.querySelectorAll('img'))
      .filter(e => e.tagName === 'IMG')
      .findIndex(e => e === this.elementRef.nativeElement);
    return {
      hasMultipleFiles,
      multiFileIndex,
    };
  }

  private uploadFile(file: File) {
    return pipe(
      concatMap(() => combineLatest([from(reduceFileSizeIfItIsAnImage(file)), of(file.name)])),
      concatMap(([blob, filename]) => this.mediaService.dataPost({ blob, filename, isGlobal: this.isGlobal })),
      tap(response => this.meta = response),
      tap(response => this.writeValue(response.id)),
      concatMap(response => this.mediaService.dataGet({
        id: response.id,
        time: response.dateUpdated,
        isGlobal: this.isGlobal,
        maxDim: this.getMaxDim(),
        disableCropTransforms: null,
      })),
      tap(response => this.elementRef.nativeElement.src = fileIsAnImage(response.meta.mimeType)
        ? response.url : getIcon(response.meta.originalFileName)),
    );
  }

  private getMaxDim() {
    const element = this.wrapperElement || this.elementRef.nativeElement;
    return Math.max(element.offsetWidth, element.offsetHeight) * window.devicePixelRatio;
  }

  private downloadMediaAndUpdateImage() {
    this.elementRef.nativeElement.style.opacity = '0';
    this.updateLoader(true);
    if (!this.getMaxDim()) { /*console.warn(`image with ID of ${this.value} has no dimensions`);*/ return of({}); }
    return pipe(
      map(() => this.value),
      takeWhileTruthy(),
      // download meta because image may be cached and we need the latest version (with the latest crop / rotation)
      concatMap(id => this.bypassTransforms ? of({} as MetaResponse) : this.mediaService.metaGet({
        id,
        isGlobal: this.isGlobal,
      })),
      catchErrorThenStop(() => this.handleApiError()),
      tap(response => this.meta = Object.deepFreeze(response)),
      map(() => this.value),
      takeWhileTruthy(),
      concatMap(id => this.mediaService.dataGet({
        id,
        maxDim: this.getMaxDim(),
        time: this.meta?.dateUpdated,
        isGlobal: this.isGlobal,
        disableCropTransforms: null,
      })),
      catchErrorThenStop(() => this.handleApiError()),
      tap(response => this.meta = Object.deepFreeze(response.meta)),
      tap(response => this.elementRef.nativeElement.src = fileIsAnImage(response.meta.mimeType)
        ? response.url : getIcon(response.meta.originalFileName)),
      finalize(() => {
        this.elementRef.nativeElement.style.opacity = '1';
        this.updateLoader(false);
        this.updatePlaceholderTemplate(false);
        if (this.elementRef.nativeElement.closest('[data-media-wrapper]')) {
          const { width, height } = getComputedStyle(this.wrapperElement || this.elementRef.nativeElement);
          if (!parseFloat(height) && this.meta.width && this.meta.height) {
            this.elementRef.nativeElement.style.height = this.wrapperElement.offsetWidth * (this.meta.height / this.meta.width) + 'px';
          } else if (!parseFloat(width) && this.meta.width && this.meta.height) {
            this.elementRef.nativeElement.style.width = this.wrapperElement.offsetHeight * (this.meta.width / this.meta.height) + 'px';
          } else {
            this.elementRef.nativeElement.style.width = this.wrapperElement.offsetWidth + 'px';
            this.elementRef.nativeElement.style.height = this.wrapperElement.offsetHeight + 'px';
          }
        }
      }),
    );
  }

  private openFileDialog() {
    const fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', getFileInputAcceptAttributeValue(this.fileAcceptTypes));
    fileInput.click();
    pipe(
      concatMap(() => fromEventPattern(h => fileInput.onchange = h) as Observable<Event>),
      map(e => {
        const files = (e.target as HTMLInputElement).files;
        if (!files || !files.length) { return null; }
        const file = files[0];
        if (!fileIsAcceptable(file, this.fileAcceptTypes, this.matSnackbar)) { return null; }
        return file;
      }),
      takeWhileTruthy(),
      concatMap(file => this.uploadFile(file)),
    ).subscribe();
  }

  private openMediaDialog() {
    const { hasMultipleFiles, multiFileIndex } = this.getMultiFileInfo();
    pipe(
      map(() => this.value),
      takeWhileTruthy(),
      concatMap(id => this.mediaService.dataGet({
        id,
        time: this.meta.dateUpdated,
        isGlobal: this.isGlobal,
        disableCropTransforms: null,
        maxDim: null,
      })),
      concatMap(response => this.matDialog.openCustom(MediaDialogComponent, {
        hasBackdrop: false,
        data: {
          meta: Object.deepFreeze({ ...response.meta, isCropped: !!response.meta.isCropped }),
          url: response.url,
          aspectRatio: this.elementRef.nativeElement.offsetWidth / this.elementRef.nativeElement.offsetHeight,
          mode: this.mode,
          isGlobal: this.isGlobal,
          fileAcceptTypes: this.fileAcceptTypes,
          multiFileUrls: !hasMultipleFiles ? null : this.multiFileProps.value(),
          multiFileIndex,
          multiFileOnChange: this.multiFileProps?.onChange,
          singleFileOnChange: hasMultipleFiles ? null : meta => {
            this.meta = meta;
            this.writeValue(meta.id);
          }
        },
      }).afterClosed()),
      takeWhileTruthy(),
      takeWhile(meta => JSON.stringify(meta) !== JSON.stringify(this.meta)),
      tap(meta => {
        if (hasMultipleFiles) {
          // we will ask the parent to update its value as well as its children
          this.multiFileProps.onChange(meta);
        } else {
          this.meta = meta;
          this.writeValue(meta.id);
        }
      })
    ).subscribe();
  }

  private placeImageInWrapperDiv() {
    if (!this.showLoader && !this.placeholderTemplate) { return; }
    this.wrapperElement = document.createElement('span');
    Object.assign<CSSStyleDeclaration, Partial<CSSStyleDeclaration>>(this.wrapperElement.style, {
      position: 'relative',
      display: 'inline-block',
      transition: 'all 0.1s',
    });
    this.wrapperElement.setAttribute('data-media-wrapper', 'true');
    const parent = isDefined(this.elementRef.nativeElement.parentNode);
    const idx = Array.from(parent.children).findIndex(e => e === this.elementRef.nativeElement);
    parent.insertBefore(this.wrapperElement, parent.children[idx]);
    this.wrapperElement.appendChild(parent.removeChild(this.elementRef.nativeElement));
  }

  private addLoaderToComponent() {
    if (!this.showLoader) { return; }
    this.loaderElement = document.createElement('div');
    Object.assign<CSSStyleDeclaration, Partial<CSSStyleDeclaration>>(this.loaderElement.style, {
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      backgroundColor: 'rgba(255,255,255,0.4)',
      display: 'grid',
      pointerEvents: 'none',
      zIndex: '1',
      opacity: '0',
      transition: 'all 0.2s',
    });
    this.wrapperElement.appendChild(this.loaderElement);
    const loader = this.viewContainerRef.createComponent<MediaLoaderComponent>(MediaLoaderComponent).instance.elementRef.nativeElement;
    Object.assign<CSSStyleDeclaration, Partial<CSSStyleDeclaration>>(loader.style, {
      alignSelf: 'center',
      justifySelf: 'center',
    });
    this.loaderElement.appendChild(isDefined(loader.parentNode).removeChild(loader));
  }

  private addNgTemplatePlaceholder() {
    if (!this.placeholderTemplate) { return; }
    const setStyles = () => {
      Object.assign<CSSStyleDeclaration, Partial<CSSStyleDeclaration>>(this.placeholderElement.style, {
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        pointerEvents: 'none',
        opacity: '1',
      });
    };
    // eslint-disable-next-line no-underscore-dangle
    if ((this.placeholderTemplate as any)._declarationLView) { // i.e. is this a templateRef?
      const template = this.viewContainerRef.createEmbeddedView(this.placeholderTemplate as TemplateRef<HTMLElement>);
      setTimeout(() => {
        this.placeholderElement = template.rootNodes.find(n => !!n.tagName);
        this.wrapperElement.appendChild(isDefined(this.placeholderElement.parentNode).removeChild(this.placeholderElement));
        setStyles();
      });
    } else { // this must be a component, not a templateRef
      this.placeholderElement = this.placeholderTemplate.elementRef.nativeElement;
      this.wrapperElement.appendChild(isDefined(this.placeholderElement.parentNode).removeChild(this.placeholderElement));
      setStyles();
    }
  }

}
