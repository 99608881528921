import { manageStateSlice } from 'g2i-ngrx-utils';

import {
  FormSubmission,
  ReferenceData,
  ReferenceDataItem,
  ReferenceDataItemDelete,
  ReferenceDataItemListCache,
  ReferenceDataItemList,
  ReferenceDataItemUpdate,
  ReferenceDataSummary,
} from '../../../shared/api-gencap/api-gencap.responses';
import { MatTableQuery, ReferenceListItemQuery } from '../../../shared/store/interfaces';

export const dataState = manageStateSlice({
  referenceListQuery: { pageIndex: 0, pageSize: 10, showArchived: false, active: '', direction: 'desc' } as MatTableQuery,
  referenceDataLists: new Array<ReferenceData>(),
  referenceDataSummaryLists: new Array<ReferenceDataSummary>(),
  submissions: new Array<FormSubmission>(),
  referenceListItems: new Array<ReferenceDataItem>(),
  referenceListItemCount: 0,
  referenceListItemCountUnfiltered: 0,
  referenceListItemNewRecord: false,
  referenceListItemDeleteRecord: false,
  referenceListItemQuery: { pageIndex: 0, pageSize: 10, sortColumn: '', sortDirection: 'desc', searchString: '' } as ReferenceListItemQuery,
  referenceListItemCache: { } as { [referenceDataId: number]: ReferenceDataItemListCache },
})({
  referenceListQuery: (state, payload: Partial<MatTableQuery>) => {
    Object.assign(state.referenceListQuery, payload);
  },
  referenceLists: (state, payload: ReferenceData[]) => {
    state.referenceDataLists = payload;
  },
  referenceSummaryLists: (state, payload: ReferenceDataSummary[]) => {
    state.referenceDataSummaryLists = payload;
  },
  referenceListCreate: (state, payload: ReferenceDataSummary) => {
    state.referenceDataSummaryLists.push(payload);
  },
  referenceListUpdate: (state, payload: ReferenceDataSummary) => {
    state.referenceDataSummaryLists[
      state.referenceDataSummaryLists.findIndex(a => a.referenceData.id === payload.referenceData.id)] = payload;
  },
  referenceListArchive: (state, payload: ReferenceData) => {
    state.referenceDataSummaryLists[
      state.referenceDataSummaryLists.findIndex(a => a.referenceData.id === payload.id)].referenceData = payload;
  },
  referenceListItems: (state, payload: ReferenceDataItemList) => {
    state.referenceListItems = payload.items;
    state.referenceListItemCount = payload.count;
    state.referenceListItemCountUnfiltered = payload.countUnfiltered;
  },
  referenceListItemNewRecord: (state, payload: boolean) => {
    state.referenceListItemNewRecord = payload;
  },
  referenceListItemDeleteRecord: (state, payload: boolean) => {
    state.referenceListItemDeleteRecord = payload;
  },
  referenceListItemQuery: (state, payload: Partial<ReferenceListItemQuery>) => {
    Object.assign(state.referenceListItemQuery, payload);
  },
  referenceListItemDelete: (state, payload: ReferenceDataItemDelete) => {
    state.referenceListItems = payload.page;
    state.referenceListItemCount = payload.count;
    state.referenceListItemCountUnfiltered = payload.countUnfiltered;
    state.referenceListItemQuery.pageIndex = payload.pageIndex;
  },
  referenceListItemUpdate: (state, payload: ReferenceDataItemUpdate) => {
    state.referenceListItems = payload.page;
    state.referenceListItemCount = payload.count;
    state.referenceListItemCountUnfiltered = payload.countUnfiltered;
  },
  referenceListItemCreate: (state, payload: ReferenceDataItem) => {
    state.referenceListItemNewRecord = true;
    state.referenceListItems.unshift(payload);
    if (state.referenceListItems.length > state.referenceListItemQuery.pageSize) {
      state.referenceListItems.pop();
    }
    state.referenceListItemCount++;
    state.referenceListItemCountUnfiltered++;
  },
  referenceListItemCacheClear: (state) => {
    state.referenceListItemCache = [];
  },
  referenceListItemCacheStart: (state, referenceDataId: number) => {
    state.referenceListItemCache[referenceDataId] = {
      status: 'retrieving',
      items: [],
    };
  },
  referenceListItemCacheComplete: (state, payload: ReferenceDataItemList) => {
    if (!payload.count) { return; }
    const datalistId = payload.items[0].referenceDataId;
    state.referenceListItemCache[datalistId] = {
      status: 'retrieved',
      items: payload.items,
    };
  },
  formSubmissions: (state, payload: FormSubmission[]) => {
    state.submissions = payload;
  },
});
