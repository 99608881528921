import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ENVIRONMENT_BASE_URL, DEVELOPING_ON_LOCALHOST, G2iAuthService } from 'g2i-ng-auth';
import { Observable } from 'rxjs';
import { concatMap, take, tap } from 'rxjs/operators';
import { STATE_MANAGERS } from 'shared/constants/injection-token.constants';
import { PossibleRouteParamsMap } from 'shared/constants/route.constants';
import { isDefined } from 'shared/utils/strict-null-check.utils';

import { environmentOverride, providerOverrides, services } from '../constants/api.constants';
import { ResolverMonitorService } from '../services/resolver-monitor';

export class ApiBaseService {

  protected readonly httpClient = inject(HttpClient);
  protected readonly authService = inject(G2iAuthService);
  protected readonly stateManagers = inject(STATE_MANAGERS);
  protected readonly developingOnLocalhost = inject(DEVELOPING_ON_LOCALHOST);
  protected readonly appEnvironmentBaseUrl = inject(APP_ENVIRONMENT_BASE_URL);
  protected readonly router = inject(Router);
  protected readonly resolverMonitor = inject(ResolverMonitorService);

  constructor(
    readonly baseUrl: () => string
  ) {
  }

  protected get serviceUrl() {
    return new Proxy({}, {
      get: <K extends string & keyof typeof providerOverrides>(
        t: any,
        service: K
      ) => this.developingOnLocalhost
          ? providerOverrides[service] || environmentOverride || this.appEnvironmentBaseUrl
          : this.appEnvironmentBaseUrl
    }) as { [key in typeof services[number]]: string };
  }

  protected get routeParam() {
    return new Proxy({}, {
      get: <K extends string & keyof PossibleRouteParamsMap>(
        t: any,
        paramName: K
      ) => this.resolverMonitor.params[paramName] as PossibleRouteParamsMap[K]
    }) as { [key in keyof PossibleRouteParamsMap]: PossibleRouteParamsMap[key] };
  }

  protected request<O>(
    endpoint: string | number,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'UPLOAD' | 'DOWNLOAD' | 'DOWNLOAD_ATTACHMENT',
    params: { [key: string]: any } = {},
    extras?: {
      unauthenticated?: boolean;
    }
  ): Observable<O> {
    return this.authService.authDetails$.pipe(
      take(1),
      concatMap(authDetails => {

        // Construct URL
        const baseUrl = this.baseUrl();

        // Ensure no double-slash on URL
        const slashBase = baseUrl.endsWith('/');
        const slashEndpoint = typeof endpoint === 'string' && endpoint.startsWith('/');
        const slash = slashBase || slashEndpoint ? '' : '/';

        // Generate final URL
        const url = `${baseUrl}${endpoint ? (slash + endpoint) : ''}`;

        // Define headers
        const headers = extras?.unauthenticated
          ? {}
          // eslint-disable-next-line @typescript-eslint/naming-convention
          : { ...this.authService.getAuthHeader(), 'x-bb-sub': authDetails.userId };

        // Prune params which are null or undefined
        if (params) {
          Object.keys(params)
            .filter(key => [null, undefined].some(val => params[key] === val))
            .forEach(key => delete params[key]);
        }

        // If downloading a file which can be directly viewed in the browser (eg an image)
        if (method === 'DOWNLOAD') {
          return this.httpClient.get(url, { params, responseType: 'arraybuffer', headers }) as any as Observable<O>;
        }

        // If downloading an attachment which cannot be directly viewed in the browser (eg a CSV report)
        if (method === 'DOWNLOAD_ATTACHMENT') {
          return this.httpClient.get(url, { params, responseType: 'blob', observe: 'response', headers })
            .pipe(
              tap(data => {
                const contentDisposition = isDefined(data.headers.get('Content-disposition'));
                const body = isDefined(data.body);
                Object.assign(document.createElement('a'), {
                  href: window.URL.createObjectURL(new Blob([body])),
                  target: '_blank',
                  download: contentDisposition.substring('attachment;filename='.length),
                }).click();
              }),
            ) as unknown as Observable<O>;
        }

        // If uploading a file
        if (method === 'UPLOAD') {
          const { file, ...otherParams } = params;
          const formData: FormData = new FormData();
          formData.append('file', file, file.name);
          Object.keysTyped(otherParams).filter(key => key !== 'file').forEach(key => formData.append(key as string, otherParams[key]));
          return this.httpClient.post<O>(url, formData, { headers });
        }

        // If doing a standard GET request
        if (method === 'GET') {
          return this.httpClient.get<O>(url, { params, headers });
        }

        // If doing a standard POST / PUT / DELETE / PATCH request
        return this.httpClient.request<O>(method, url, { body: params, headers });
      }),
    );
  }
}
