<ng-container *ngIf="fields.$ | async; let $;">
  <div class="header">
    <div class="title">
      {{$.metaCopy$.originalFileName}}
    </div>
    <div class="header-actions">
      <mat-icon mat-ripple
        matRippleColor="rgba(255,255,255,0.2)"
        matTooltip="Click to replace this file"
        (click)="onClickReplace()"
        *ngIf="!$.editMode$ && canReplace">
        swap_horiz
      </mat-icon>
      <mat-icon mat-ripple
        matRippleColor="rgba(255,255,255,0.2)"
        matTooltip="Click to download this file"
        (click)="onClickDownload()"
        *ngIf="!$.editMode$">
        file_download
      </mat-icon>
      <mat-icon mat-ripple
        matRippleColor="rgba(255,255,255,0.2)"
        matTooltip="Click to crop or rotate this image"
        (click)="onClickEdit()"
        *ngIf="isAnImage && !$.editMode$ && canEdit">
        edit
      </mat-icon>
      <mat-icon mat-ripple
        matRippleColor="rgba(255,255,255,0.2)"
        matTooltip="Click to save edits"
        (click)="onClickSave()"
        *ngIf="isAnImage && $.editMode$">
        save
      </mat-icon>
      <mat-icon mat-ripple
        matRippleColor="rgba(255,255,255,0.2)"
        [matTooltip]="'Click to ' + ($.editMode$ ? 'exit edit mode' : 'close this window')"
        (click)="onClickClose()">close</mat-icon>
    </div>
  </div>
  <div class="content"
    [class.show-file-nav]="this.data.multiFileUrls">
    <button mat-button
      class="button-nav"
      (click)="onClickNav(-1)"
      *ngIf="this.data.multiFileUrls && !$.editMode$">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <img [src]="$.urlToDisplay$ | safe"
      [class.edit-mode]="$.editMode$"
      [class.hide]="$.loading$"
      [class.show-file-nav]="this.data.multiFileUrls"
      (click)="onClickImage()"
      #image>
    <div class="loader"
      [class.show]="$.loading$">
      <app-media-loader></app-media-loader>
    </div>
    <button mat-button
      class="button-nav"
      (click)="onClickNav(1)"
      *ngIf="this.data.multiFileUrls && !$.editMode$">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
  <div class="footer"
    *ngIf="$.editMode$">
    <mat-icon mat-ripple
      matRippleColor="rgba(255,255,255,0.2)"
      matTooltip="Rotate this image 90º left"
      (click)="onClickRotate(-90)">rotate_left</mat-icon>
    <mat-icon mat-ripple
      matRippleColor="rgba(255,255,255,0.2)"
      matTooltip="Rotate this image 90º right"
      (click)="onClickRotate(90)">rotate_right</mat-icon>
    <mat-icon mat-ripple
      matRippleColor="rgba(255,255,255,0.2)"
      matTooltip="Click to toggle crop region"
      (click)="onClickCrop()">crop</mat-icon>
  </div>
</ng-container>