import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { combineManagers } from 'g2i-ngrx-utils';
import { shellState } from 'shared/store/shell.state';

import { formDashboardState } from '../../app/form-dashboard/form-dashboard.state';
import { dataState } from '../../app/main/data/data.state';
import { formState } from '../../app/main/forms/forms.state';
import { AppState } from './interfaces';
import { userAudienceState } from '../../app/main/user-audience/user-audience.state';

export const managers = combineManagers({
  shell: shellState,
  form: formState,
  user: userAudienceState,
  data: dataState,
  dashboard: formDashboardState,
});

export const reducerToken = new InjectionToken<ActionReducerMap<AppState>>('Reducers');

export const getReducers = () => managers.reducers;
