import { OrganizationAppGuard } from 'g2i-ng-auth';
import { AppRoute, shellRoutes } from 'shared/constants/route.consts';
import { ShellAppGuard } from 'shared/services/shell-app.guard';
import { SyncGuard } from 'shared/services/sync.guard';

import { FormDashboardResolver } from './form-dashboard/form-dashboard.resolver';
import { GencapMobileGuard } from './gencap-mobile.guard';
import { MainResolver } from './main/main.resolver';


export const routes: AppRoute[] = [
  ...shellRoutes,
  {
    path: 'app',
    canActivate: [SyncGuard],
    data: {
      syncGuards: [ShellAppGuard, GencapMobileGuard, OrganizationAppGuard],
    },
    runGuardsAndResolvers: 'always', // Required for OrganizationAppGuard to function smoothly
    children: [
      {
        path: ':organizationid',
        loadChildren: () => import('./main/main.routes').then(m => m.routes),
        resolve: { data: MainResolver },
      },
    ],
  },
  {
    path: 'form-dashboard/:formDashboardId',
    loadChildren: () => import('./form-dashboard/form-dashboard.routes').then(m => m.routes),
    resolve: { data: FormDashboardResolver },
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.routes').then(m => m.routes),
  },
  {
    path: '**',
    redirectTo: 'landing',
  },
];
