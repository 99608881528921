import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, fromEvent } from 'rxjs';
import { concatMap, delay, filter, startWith, tap } from 'rxjs/operators';
import { STATE_MANAGERS } from 'shared/constants/injection-token.constants';
import { ShellStateManagers } from 'shared/store/interfaces';
import { shellState } from 'shared/store/shell.state';
import { manageFields } from 'shared/utils/component.utils';
import { pipe } from 'shared/utils/rxjs.utils';

@Component({
  selector: 'app-shell-header',
  templateUrl: './shell-header.component.html',
  styleUrls: ['./shell-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatSidenavModule, RouterModule, MatIconModule]
})
export class ShellHeaderComponent implements AfterViewInit, OnDestroy {

  @ViewChild('search')
  readonly search!: ElementRef<HTMLDivElement>;
  @ViewChild('title')
  readonly title!: ElementRef<HTMLDivElement>;
  @ViewChild('actions')
  readonly actions!: ElementRef<HTMLDivElement>;
  @Input()
  breadcrumbVars = {} as { [key: string]: string };
  errorMessage!: string;
  errorDescription?: string;
  readonly recheck$ = new BehaviorSubject(false);
  readonly effects$ = combineLatest([ this.reactToBreadcrumbUpdates(), this.maintainSearchBoxWidth() ]);
  readonly fields = manageFields<ShellHeaderComponent>(this);

  constructor(
    @Inject(STATE_MANAGERS)
    readonly stateManagers: ShellStateManagers,
    readonly store: Store<{ shell: typeof shellState.initialState }>,
    readonly route: ActivatedRoute,
    readonly router: Router,
    readonly elementRef: ElementRef<HTMLDivElement>,
  ) {
  }

  ngAfterViewInit() {
    this.moveActionsIntoPageHeader(this.elementRef);
  }

  ngOnDestroy() {
    this.removePageActionsFromPageHeader(this.elementRef);
  }

  onClickBreadcrumb(path: string) {
    this.router.navigateByUrlNoHistory(path);
  }

  private reactToBreadcrumbUpdates() {
    return pipe(
      concatMap(() => this.fields.breadcrumbVars),
      filter(vars => !!Object.keys(vars).length),
      tap(() => shellState.dispatch.breadcrumbVars(__filename, this.breadcrumbVars)),
    );
  }

  private maintainSearchBoxWidth() {
    return pipe(
      concatMap(() => combineLatest([
        this.fields.title,
        this.fields.search,
      ])),
      concatMap(() => combineLatest([
        pipe(concatMap(() => fromEvent(window, 'resize')), startWith({}), delay(0)),
        this.recheck$,
      ])),
      tap(() => {
        const totalWidth = window.innerWidth;
        const leftWidth = this.title.nativeElement.getBoundingClientRect().left + this.title.nativeElement.getBoundingClientRect().width;
        const rightWidth = totalWidth - this.actions.nativeElement.getBoundingClientRect().left;
        const search = this.search.nativeElement;
        const searchWidthMax = 650;
        if (Math.max(leftWidth, rightWidth) < ((totalWidth - searchWidthMax) / 2)) {
          search.style.position = 'absolute';
          search.style.left = `${(totalWidth - searchWidthMax) / 2}px`;
          search.style.width = searchWidthMax + 'px';
          search.style.marginLeft = '0';
        } else {
          search.style.position = 'initial';
          search.style.flex = '1';
          search.style.width = 'initial';
          search.style.left = 'initial';
          search.style.marginLeft = '8px';
        }
      }),
    );
  }

  private moveActionsIntoPageHeader = (headerContent: ElementRef<HTMLDivElement>) => {
    headerContent.nativeElement.style.display = 'none';
    setTimeout(() => { // we need to wait for element ( id="page-header-actions" ) to be added to the DOM.
      const pageHeader = document.getElementById('page-header-actions');
      const parent = headerContent.nativeElement.parentElement;
      if (pageHeader && parent) {
        pageHeader.appendChild(parent.removeChild(headerContent.nativeElement));
        headerContent.nativeElement.style.display = '';
        this.recheck$.next(!this.recheck$.value);
      }
    });
  };

  private removePageActionsFromPageHeader = (headerContent: ElementRef<HTMLDivElement>) => {
    const pageHeader = document.getElementById('page-header-actions');
    if (pageHeader && headerContent) {
      pageHeader.removeChild(headerContent.nativeElement);
    }
  };

}
