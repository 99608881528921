import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class ErrorService extends ErrorHandler {

  static readonly errorHandlers = {} as { [errorName: string]: string };

  constructor(
    readonly matSnackbar: MatSnackBar,
    readonly zone: NgZone,
  ) {
    super();
  }

  public override handleError(error: any) {
    super.handleError(error);
    // https://stackoverflow.com/a/50509861/1087131
    this.zone.run(() => this.matSnackbar.showErrorUnexpected('Something unexpected happened.'));
    const err = Object.keys(ErrorService.errorHandlers).find(e => error.toString().includes(e));
    if (err) {
      console.error(ErrorService.errorHandlers[err]);
    }
  }

  public registerHandler(errorName: string, errorMessage: string) {
    ErrorService.errorHandlers[errorName] = errorMessage;
  }

  public unRegisterHandler(errorName: string) {
    delete ErrorService.errorHandlers[errorName];
  }
}
