import * as dayjs from 'dayjs';
import { manageStateSlice } from 'g2i-ngrx-utils';

import { DAY_COUNT } from './form-dashboard.consts';
import { FormDashboardData, FormDashboardDetails, FormDashboardOption } from '../../shared/api-gencap/api-gencap.responses';

export const formDashboardState = manageStateSlice({
  formName: '',
  formDescription: '',
  dateProcessed: '',
  filters: new Array<FormDashboardOption>(),
  chartData: new Array<{ day: string; data: FormDashboardData[] }>(),
  timezone: '',
  selectedFilter: { key: '', label: '' } as FormDashboardOption,
  previouslySelectedFilter: { key: '', label: '' } as FormDashboardOption | null,
  yAxisIncrement: 0,
  yAxisIncrementDirty: true,
  previousYAxisIncrement: 0,
  selectedDate: '',
  chartInitialized: false,
  animating: false,
})({
  details: (state, payload: FormDashboardDetails) => {
    state.formName = payload.formName;
    state.formDescription = payload.formDescription;
    state.dateProcessed = payload.dateProcessed;
    state.filters = payload.filters;
    state.timezone = payload.timezone;
    const dataGrouped = new Array<{ day: string; data: FormDashboardData[] }>();
    if (payload.data.length) {
      const latestDay = dayjs(payload.data[payload.data.length - 1].day);
      (new Array(DAY_COUNT)).fill(null).forEach((e, i) => {
        const dayString = latestDay.subtract(i, 'days').format('YYYY-MM-DD');
        dataGrouped.unshift({
          day: dayString,
          data: payload.data.filter(d => d.day === dayString),
        });
      });
      state.chartData = dataGrouped;
    }
  },
  chartInitialized: (state) => {
    state.chartInitialized = true;
  },
  selectedFilter: (state, payload: FormDashboardOption) => {
    state.previouslySelectedFilter = state.selectedFilter;
    state.selectedFilter = payload;
    state.yAxisIncrementDirty = true;
  },
  previouslySelectedFilter: (state, payload: FormDashboardOption) => {
    state.previouslySelectedFilter = payload;
  },
  selectedDate: (state, date: string) => {
    state.selectedDate = date;
  },
  yAxisIncrement: (state, payload: number) => {
    state.previousYAxisIncrement = !state.previousYAxisIncrement ? payload : state.yAxisIncrement;
    state.yAxisIncrement = payload;
    state.yAxisIncrementDirty = false;
  },
  previousYAxisIncrement: (state, payload: number) => {
    state.previousYAxisIncrement = payload;
  },
  animating: (state, payload: boolean) => {
    state.animating = payload;
  },
});
