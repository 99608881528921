import { AbstractControl } from '@angular/forms';
import * as dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { TypedFormArray } from 'shared/types/form.types';
import { regexp } from './regexp.constants';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);


export const rules = {
  required: <T extends { toString: () => string }>(
    options?: {
      message?: string;
      onCondition?: () => boolean;
    }
  ) => (control: AbstractControl<T>) => {
    if (options?.onCondition && !options.onCondition()) { return null; }
    if (!control.touched) { return null; }
    const errorFound = control.value === null || control.value === undefined || !control.value.toString().trim();
    return errorFound ? { message: options?.message || 'This field is required' } : null;
  },
  custom: <T>(fn: (val: T) => boolean, message = 'This field is invalid') => (control: AbstractControl<T>) =>
    control.value && !fn(control.value) ? { message } : null,
  pattern: (options: { name: keyof typeof regexp; message?: string }) => (control: AbstractControl<string>) => {
    const { regex, message } = regexp[options.name];
    return (control.value && !!control.value.trim() && !regex.test(control.value)) ? { message: options.message || message } : null;
  },
  mustMatch: (otherField: string, otherFieldLabel = otherField) => (control: AbstractControl<any>) => {
    if (!control.parent
      || (!control.value && !control.parent.value[otherField])
      || (control.value && !control.parent.value[otherField])
    ) { return null; }
    return control.value !== control.parent.value[otherField]
      ? { message: `Must match ${otherFieldLabel}` } : null;
  },
  notEmpty: (options?: {
    message?: string;
    onCondition?: () => boolean;
  }) => (control: AbstractControl<any[]>) => {
    if (options?.onCondition && !options.onCondition()) { return null; }
    if (!control.touched) { return null; }
    if (!control.value) { return null; }
    const errorFound = !control.value.length;
    return errorFound ? { message: options?.message || 'At least one required' } : null;
  },
  noOp: () => (control: AbstractControl<string>) => null as any,
  exactLength: (len: number) => (control: AbstractControl<string>) =>
    control.value && control.value.toString().trim().length !== len
      ? { message: `Must be ${len} characters` } : null,
  minLength: (len: number) => (control: AbstractControl<string>) =>
    control.value && control.value.toString().trim().length < len
      ? { message: `Must be at least ${len} characters` } : null,
  maxLength: (len: number) => (control: AbstractControl<string>) =>
    control.value && control.value.toString().trim().length > len
      ? { message: `Must be no more than ${len} characters` } : null,
  integer: () => (control: AbstractControl<number>) =>
    control.value && !isNaN(control.value) && control.value.toString().indexOf('.') !== -1
      ? { message: 'Must be a whole number' } : null,
  minNum: (min: number) => (control: AbstractControl<number>) =>
    control.value && !isNaN(control.value) && +control.value < min
      ? { message: `Must be at least ${min}` } : null,
  maxNum: (max: number, message?: string) => (control: AbstractControl<number>) =>
    control.value && !isNaN(control.value) && +control.value > max
      ? { message: message ? message : `Must be no more than ${max}` } : null,
  decimalPlaces: (max: number) => (control: AbstractControl<number>) =>
    control.value && !isNaN(control.value) && control.value.toString().indexOf('.') !== -1
      && control.value.toString().length - (control.value.toString().indexOf('.') + 1) > max
      ? { message: `Can have up to ${max} decimal place${max === 1 ? '' : 's'}` } : null,
  minDate: (min: string, format = 'YYYY-MM-DD') => (control: AbstractControl<string>) => {
    if (!control.value) { return null; }
    const val = dayjs(control.value, format);
    return val.isValid() && val.isBefore(dayjs(min, format))
      ? { message: `Can't be earlier than ${min}`, rule: 'minDate', constraint: min } : null;
  },
  maxDate: (max: string, format = 'YYYY-MM-DD') => (control: AbstractControl<string>) => {
    if (!control.value) { return null; }
    const val = dayjs(control.value, format);
    return val.isValid() && val.isAfter(dayjs(max, format))
      ? { message: `Can't be later than ${max}`, rule: 'maxDate', constraint: max } : null;
  },
  minTime: (min: string) => (control: AbstractControl<string>) => {
    if (!control.value || !control.value.includes(':')) { return null; }
    const [hour, minute] = control.value.split(':').map(v => +v);
    const val = dayjs().add(hour, 'hour').add(minute, 'minute').toDate().getTime();
    const [hour2, minute2] = min.split(':').map(v => +v);
    const val2 = dayjs().add(hour2, 'hour').add(minute2, 'minute').toDate().getTime();
    return val < val2
      ? { message: `Can't be earlier than ${min}`, rule: 'minDate', constraint: min } : null;
  },
  maxTime: (max: string) => (control: AbstractControl<string>) => {
    if (!control.value || !control.value.includes(':')) { return null; }
    const [hour, minute] = control.value.split(':').map(v => +v);
    const val = dayjs().add(hour, 'hour').add(minute, 'minute').toDate().getTime();
    const [hour2, minute2] = max.split(':').map(v => +v);
    const val2 = dayjs().add(hour2, 'hour').add(minute2, 'minute').toDate().getTime();
    return val > val2
      ? { message: `Can't be later than ${max}`, rule: 'maxDate', constraint: max } : null;
  },
  beforeDate: (otherField: string, otherFieldLabel = otherField, format = 'YYYY-MM-DD', allowSameDay = false) =>
    (control: AbstractControl<string>) => {
      if (!control.parent
        || (!control.value && !control.parent.value[otherField])
        || (control.value && !control.parent.value[otherField])
      ) { return null; }
      const valThis = dayjs(control.value, format);
      const valOther = dayjs(control.parent.value[otherField]);
      const isBefore = allowSameDay ? valThis.isSameOrBefore(valOther, 'day') : valThis.isBefore(valOther);
      return !isBefore ? { message: `Must be before ${otherFieldLabel}` } : null;
    },
  afterDate: (otherField: string, otherFieldLabel = otherField, format = 'YYYY-MM-DD', allowSameDay = false) =>
    (control: AbstractControl<string>) => {
      if (!control.parent
        || (!control.value && !control.parent.value[otherField])
        || (control.value && !control.parent.value[otherField])
      ) { return null; }
      const valThis = dayjs(control.value, format);
      const valOther = dayjs(control.parent.value[otherField]);
      const isAfter = allowSameDay ? valThis.isSameOrAfter(valOther, 'day') : valThis.isAfter(valOther);
      return !isAfter ? { message: `Must be after ${otherFieldLabel}` } : null;
    },
  minArrayLength: <T>(min: number, message?: string) => (control: AbstractControl<T[]>) =>
    control.value && Array.isArray(control.value) && control.value.length < min
      ? { message: message || `Must have at least ${min} item${min === 1 ? '' : 's'}` } : null,
  maxArrayLength: <T>(max: number) => (control: AbstractControl<T[]>) =>
    control.value && Array.isArray(control.value) && control.value.length > max
      ? { message: `Must have no more than ${max} item${max === 1 ? '' : 's'}` } : null,
  timepicker: (message = 'Must have a valid time') => (control: AbstractControl<string>) => {
    const timeRegex = /^[0-2][0-9]:[0-5][0-9]$/;
    if (control.value === null || control.value === undefined || control.value === '') {
      return null; // allow blank values
    }
    const errorFound = !timeRegex.test(control.value);
    return errorFound ? { message } : null;
  },
  timePickerAfterTime: (otherField: string, otherFieldLabel = otherField, allowSame = false) =>
    (control: AbstractControl<string>) => {
      if (!control.parent
        || (!control.value && !control.parent.value[otherField])
        || (control.value && !control.parent.value[otherField])
      ) { return null; }
      const valThis = dayjs('2020-01-01 ' + control.value, 'YYYY-MM-DD HH:mm');
      const valOther = dayjs('2020-01-01 ' + control.parent.value[otherField], 'YYYY-MM-DD HH:mm');
      const isAfter = allowSame ? valThis.isSameOrAfter(valOther, 'day') : valThis.isAfter(valOther);
      return !isAfter ? { message: `Must be after ${otherFieldLabel}` } : null;
    },
  minFormArrayLength: <T>(len: number) => (control: TypedFormArray<T>) =>
    control.value && control.value.toString().trim().length < len
      ? { message: `Must have at least ${len} items` } : null,
  setupLocationRadius: <T>() => (control: AbstractControl<T>) => {
    if (!control.value) { return null; }
    const dist = control.parent?.value.distanceFromLocation as number;
    return dist < 1 ? null : { message: 'Setup location cannot be further than 1km away from place marker' };
  },
};
