<ng-container *ngIf="fields.$ | async; let $">
  <div class="page-header">
    <ng-content select="[header]"></ng-content>
  </div>
  <ng-container *ngIf="imageIsEditable">
    <div class="global-page-section-header">
      <div class="global-page-section-title">Profile picture</div>
      <div class="global-page-section-description">
        {{imageInstruction}}
      </div>
    </div>
    <div class="image-wrapper">
      <img class="image"
        app-media
        #projectImage
        [formControl]="formGroup.controls.imageId"
        [fileAcceptTypes]="'image'"
        [placeholder]="'assets/assignment_icon.svg'"
        [mode]="'replaceable'"
        [isGlobal]="true"
        [placeholder]="'assets/g2i-auth/account_circle.svg'">
      <button mat-button
        color="primary"
        [appMediaTriggerFor]="projectImage">
        EDIT
      </button>
    </div>
  </ng-container>
  <div class="global-page-section-header">
    <div class="global-page-section-title">Basics</div>
    <div class="global-page-section-description">
      {{basicsInstruction}}
    </div>
  </div>
  <mat-form-field>
    <mat-label>First name *</mat-label>
    <input matInput
      [formControl]="formGroup.controls.firstName" />
    <mat-error>{{ formGroup.controls.firstName.errors?.message }}</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Surname *</mat-label>
    <input matInput
      [formControl]="formGroup.controls.lastName" />
    <mat-error>{{ formGroup.controls.lastName.errors?.message }}</mat-error>
  </mat-form-field>
  <div class="global-page-section-header">
    <div class="global-page-section-title">Email</div>
    <div class="global-page-section-description">
      {{emailInstruction}}
    </div>
  </div>
  <mat-form-field>
    <mat-label>Email address</mat-label>
    <input matInput
      [formControl]="formGroup.controls.email" />
    <mat-error>{{ formGroup.controls.email.errors?.message }}</mat-error>
  </mat-form-field>
  <div class="page-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</ng-container>