/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterStateSnapshot } from '@angular/router';
import {
  catchError,
  filter as rxFilter,
  isObservable,
  map,
  Observable,
  of,
  OperatorFunction,
  takeWhile,
  UnaryFunction,
} from 'rxjs';
import { errorStatuses } from 'shared/constants/error-status.constants';
import { LookupUtils, ObservableOrNot, ValueOf } from 'shared/types/misc.types';

/**
 * Functionally identical to takeWhile, except that it takes no arguments and always returns a non-null type
 */
export const takeWhileTruthy = <T>() =>
  (source$: Observable<T | null | undefined>) =>
    source$.pipe(takeWhile(value => !!value)) as Observable<T>;

/**
 * Functionally identical to filter, except that it takes no arguments and always returns a non-null type
 */
export const filterTruthy = <T>() =>
  (source$: Observable<T | null | undefined>) =>
    source$.pipe(rxFilter(value => !!value)) as Observable<NonNullable<T>>;

/**
 * Catch any HTTP errors and handle them using the supplied errorCatchers object.
 */
export function catchHttpErrorsThenStop(
  context: { matSnackBar: MatSnackBar },
  errorCatchers?: Partial<{[key in keyof Omit<typeof errorStatuses, LookupUtils>]: (error: typeof errorStatuses[key]['errorShape']) => unknown}>,
) {
  return <T>(
    source$: Observable<T>
  ) => source$.pipe(
    catchError(error => {
      if (errorCatchers) {
        const catcher = Object.keysTyped(errorCatchers)
          .find(key => error.status === errorStatuses[key].id);
        if (catcher) {
          errorCatchers[catcher]?.(error);
          return of(null);
        }
      }
      context.matSnackBar.showError(`Something unexpected happened (${error.status})`);
      return of(null);
    }),
    takeWhileTruthy(),
  ) as Observable<T>;
}

/**
 * Functionally similar to catchError, except that it will terminate the observable chain if an error is encountered.
 *
 * @param errorHandler - A side-effect to be run if an error is encountered.
 * Note:
 * * It is the consumers responsibility to rethrow the error if necessary.
 * * If you are dealing with API errors, `catchErrorStatusThenStop()` is more appropriate.
 *
 * Example usage:
 * ```ts
 * pipe(
 *   concatMap(() => this.callSomeObservableWhichMightThrowAnError()),
 *   catchErrorThenStop(error => this.matsnackbar.showError(error.message)),
 * )
 * ```
 */
export const catchErrorThenStop = <T, O = T>(
  errorHandler: (
    error: any
  ) => ObservableOrNot<O>) => (
    source$: Observable<T>
  ) => source$.pipe(
    catchError(error => {
      const errorResult = errorHandler(error);
      return (isObservable(errorResult) ? errorResult.pipe(map(() => null)) : of(null)) as any as Observable<any>;
    }),
    takeWhileTruthy(),
  ) as Observable<T>;

/**
 * Catches any errors that may be thrown in a resolver and redirects to the supplied error page url
 */
export function catchResolverErrorsThenRedirect(
  context: { router: Router; location: Location },
  state: RouterStateSnapshot,
  errorUrl: string,
) {
  return <T>(
    source$: Observable<T>
  ) => source$.pipe(
    catchError(error => {
      context.router.navigateToErrorPage({
        errorCode: error.status,
        requestedUrl: state.url,
        errorUrl,
        location: context.location,
      });
      return of(null);
    }),
    takeWhileTruthy(),
  ) as Observable<T>;
}

/**
 * Functionally similar to catchError, except it allows you to handle a specific error code.
 * The function that you supply can return a value that can be passed into the next observable operator.
 *
 * @param errorCode - If the error status code matches, the second argument will be executed (if it is supplied).
 * @param errorHandler - A function that will be executed if the error code matches.
 *
 * Example usage:
 * ```ts
 * pipe(
 *   concatMap(() => this.api.makeRequest()),
 *   catchErrorStatusThenStop(httpErrors.notFound, error => this.matsnackbar.showError('Not found')),
 * )
 * ```
 */
export const catchErrorStatusThenContinue = <T, V extends ValueOf<typeof errorStatuses>
  & { id: number; errorShape: any }, O = T>(
    status: V,
    errorHandler?: (error: V['errorShape']) => ObservableOrNot<O>
  ) => (
    source$: Observable<T>
  ) => source$.pipe(
    catchError(error => {
      if (error.status === status.id) {
        if (!errorHandler) {
          return of(null);
        }
        const errorResult = errorHandler(error);
        return (isObservable(errorResult) ? errorResult.pipe(map(() => null)) : of(null)) as any as Observable<any>;
      } else {
        throw error;
      }
    }),
  ) as Observable<T | O>;

export function pipe<A>(op1: OperatorFunction<unknown, A>): Observable<A>;
export function pipe<A, B>(op1: OperatorFunction<unknown, A>, op2: OperatorFunction<A, B>): Observable<B>;
export function pipe<A, B, C>(
  op1: OperatorFunction<unknown, A>,
  op2: OperatorFunction<A, B>,
  op3: OperatorFunction<B, C>): Observable<C>;
export function pipe<A, B, C, D>(
  op1: OperatorFunction<unknown, A>,
  op2: OperatorFunction<A, B>,
  op3: OperatorFunction<B, C>,
  op4: OperatorFunction<C, D>
): Observable<D>;
export function pipe<A, B, C, D, E>(
  op1: OperatorFunction<unknown, A>,
  op2: OperatorFunction<A, B>,
  op3: OperatorFunction<B, C>,
  op4: OperatorFunction<C, D>,
  op5: OperatorFunction<D, E>
): Observable<E>;
export function pipe<A, B, C, D, E, F>(
  op1: OperatorFunction<unknown, A>,
  op2: OperatorFunction<A, B>,
  op3: OperatorFunction<B, C>,
  op4: OperatorFunction<C, D>,
  op5: OperatorFunction<D, E>,
  op6: OperatorFunction<E, F>
): Observable<F>;
export function pipe<A, B, C, D, E, F, G>(
  op1: OperatorFunction<unknown, A>,
  op2: OperatorFunction<A, B>,
  op3: OperatorFunction<B, C>,
  op4: OperatorFunction<C, D>,
  op5: OperatorFunction<D, E>,
  op6: OperatorFunction<E, F>,
  op7: OperatorFunction<F, G>
): Observable<G>;
export function pipe<A, B, C, D, E, F, G, H>(
  op1: OperatorFunction<unknown, A>,
  op2: OperatorFunction<A, B>,
  op3: OperatorFunction<B, C>,
  op4: OperatorFunction<C, D>,
  op5: OperatorFunction<D, E>,
  op6: OperatorFunction<E, F>,
  op7: OperatorFunction<F, G>,
  op8: OperatorFunction<G, H>
): Observable<H>;
export function pipe<A, B, C, D, E, F, G, H, I>(
  op1: OperatorFunction<unknown, A>,
  op2: OperatorFunction<A, B>,
  op3: OperatorFunction<B, C>,
  op4: OperatorFunction<C, D>,
  op5: OperatorFunction<D, E>,
  op6: OperatorFunction<E, F>,
  op7: OperatorFunction<F, G>,
  op8: OperatorFunction<G, H>,
  op9: OperatorFunction<H, I>
): Observable<I>;
export function pipe<A, B, C, D, E, F, G, H, I, J>(
  op1: OperatorFunction<unknown, A>,
  op2: OperatorFunction<A, B>,
  op3: OperatorFunction<B, C>,
  op4: OperatorFunction<C, D>,
  op5: OperatorFunction<D, E>,
  op6: OperatorFunction<E, F>,
  op7: OperatorFunction<F, G>,
  op8: OperatorFunction<G, H>,
  op9: OperatorFunction<H, I>,
  op10: OperatorFunction<I, J>,
): Observable<J>;
export function pipe<A, B, C, D, E, F, G, H, I, J, K>(
  op1: OperatorFunction<unknown, A>,
  op2: OperatorFunction<A, B>,
  op3: OperatorFunction<B, C>,
  op4: OperatorFunction<C, D>,
  op5: OperatorFunction<D, E>,
  op6: OperatorFunction<E, F>,
  op7: OperatorFunction<F, G>,
  op8: OperatorFunction<G, H>,
  op9: OperatorFunction<H, I>,
  op10: OperatorFunction<I, J>,
  op11: OperatorFunction<J, K>,
): Observable<K>;
export function pipe<A, B, C, D, E, F, G, H, I, J, K, L>(
  op1: OperatorFunction<unknown, A>,
  op2: OperatorFunction<A, B>,
  op3: OperatorFunction<B, C>,
  op4: OperatorFunction<C, D>,
  op5: OperatorFunction<D, E>,
  op6: OperatorFunction<E, F>,
  op7: OperatorFunction<F, G>,
  op8: OperatorFunction<G, H>,
  op9: OperatorFunction<H, I>,
  op10: OperatorFunction<I, J>,
  op11: OperatorFunction<J, K>,
  op12: OperatorFunction<K, L>,
): Observable<L>;
export function pipe<A, B, C, D, E, F, G, H, I, J, K, L, M>(
  op1: OperatorFunction<unknown, A>,
  op2: OperatorFunction<A, B>,
  op3: OperatorFunction<B, C>,
  op4: OperatorFunction<C, D>,
  op5: OperatorFunction<D, E>,
  op6: OperatorFunction<E, F>,
  op7: OperatorFunction<F, G>,
  op8: OperatorFunction<G, H>,
  op9: OperatorFunction<H, I>,
  op10: OperatorFunction<I, J>,
  op11: OperatorFunction<J, K>,
  op12: OperatorFunction<K, L>,
  op13: OperatorFunction<L, M>,
): Observable<M>;
export function pipe<A, B, C, D, E, F, G, H, I, J, K, L, M, N>(
  op1: OperatorFunction<unknown, A>,
  op2: OperatorFunction<A, B>,
  op3: OperatorFunction<B, C>,
  op4: OperatorFunction<C, D>,
  op5: OperatorFunction<D, E>,
  op6: OperatorFunction<E, F>,
  op7: OperatorFunction<F, G>,
  op8: OperatorFunction<G, H>,
  op9: OperatorFunction<H, I>,
  op10: OperatorFunction<I, J>,
  op11: OperatorFunction<J, K>,
  op12: OperatorFunction<K, L>,
  op13: OperatorFunction<L, M>,
  op14: OperatorFunction<M, N>,
): Observable<N>;
export function pipe<A, B, C, D, E, F, G, H, I, J, K, L, M, N, O>(
  op1: OperatorFunction<unknown, A>,
  op2: OperatorFunction<A, B>,
  op3: OperatorFunction<B, C>,
  op4: OperatorFunction<C, D>,
  op5: OperatorFunction<D, E>,
  op6: OperatorFunction<E, F>,
  op7: OperatorFunction<F, G>,
  op8: OperatorFunction<G, H>,
  op9: OperatorFunction<H, I>,
  op10: OperatorFunction<I, J>,
  op11: OperatorFunction<J, K>,
  op12: OperatorFunction<K, L>,
  op13: OperatorFunction<L, M>,
  op14: OperatorFunction<M, N>,
  op15: OperatorFunction<N, O>,
): Observable<O>;
export function pipe<A, B, C, D, E, F, G, H, I, J, K, L, M, N, O, P>(
  op1: OperatorFunction<unknown, A>,
  op2: OperatorFunction<A, B>,
  op3: OperatorFunction<B, C>,
  op4: OperatorFunction<C, D>,
  op5: OperatorFunction<D, E>,
  op6: OperatorFunction<E, F>,
  op7: OperatorFunction<F, G>,
  op8: OperatorFunction<G, H>,
  op9: OperatorFunction<H, I>,
  op10: OperatorFunction<I, J>,
  op11: OperatorFunction<J, K>,
  op12: OperatorFunction<K, L>,
  op13: OperatorFunction<L, M>,
  op14: OperatorFunction<M, N>,
  op15: OperatorFunction<N, O>,
  op16: OperatorFunction<O, P>,
): Observable<P>;
export function pipe<A, B, C, D, E, F, G, H, I, J, K, L, M, N, O, P, Q>(
  op1: OperatorFunction<unknown, A>,
  op2: OperatorFunction<A, B>,
  op3: OperatorFunction<B, C>,
  op4: OperatorFunction<C, D>,
  op5: OperatorFunction<D, E>,
  op6: OperatorFunction<E, F>,
  op7: OperatorFunction<F, G>,
  op8: OperatorFunction<G, H>,
  op9: OperatorFunction<H, I>,
  op10: OperatorFunction<I, J>,
  op11: OperatorFunction<J, K>,
  op12: OperatorFunction<K, L>,
  op13: OperatorFunction<L, M>,
  op14: OperatorFunction<M, N>,
  op15: OperatorFunction<N, O>,
  op16: OperatorFunction<O, P>,
  op17: OperatorFunction<P, Q>,
): Observable<Q>;
export function pipe<A, B, C, D, E, F, G, H, I, J, K, L, M, N, O, P, Q, R>(
  op1: OperatorFunction<unknown, A>,
  op2: OperatorFunction<A, B>,
  op3: OperatorFunction<B, C>,
  op4: OperatorFunction<C, D>,
  op5: OperatorFunction<D, E>,
  op6: OperatorFunction<E, F>,
  op7: OperatorFunction<F, G>,
  op8: OperatorFunction<G, H>,
  op9: OperatorFunction<H, I>,
  op10: OperatorFunction<I, J>,
  op11: OperatorFunction<J, K>,
  op12: OperatorFunction<K, L>,
  op13: OperatorFunction<L, M>,
  op14: OperatorFunction<M, N>,
  op15: OperatorFunction<N, O>,
  op16: OperatorFunction<O, P>,
  op17: OperatorFunction<P, Q>,
  op18: OperatorFunction<Q, R>,
): Observable<R>;

/**
 * A top-level pipe operator which makes observable chains easier to read.
 * Usage:
 * ```ts
 * pipe(
 *   concatMap(() => this.api.makeRequest()),
 *   tap(response => myManager.dispatch.thing(__filename, response)),
 * );
 * ```
 */
export function pipe(...operations: OperatorFunction<any, any>[]): Observable<any> {
  return pipeFromArray(operations)(of({}));
}

export function pipeFromArray<T, R>(fns: Array<UnaryFunction<T, R>>): UnaryFunction<T, R> {
  if (fns.length === 1) {
    return fns[0];
  }
  return function piped(input: T): R {
    return fns.reduce((prev: any, fn: UnaryFunction<T, R>) => fn(prev), input as any);
  };
}
