import { Injectable } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';

import { UserPersonProfile } from '../api-organization.response';
import { UpdateUserPersonProfileRequest } from './api-user.requests';

@Injectable({ providedIn: 'root' })
export class ApiUserService extends ApiBaseService {

  constructor() {
    super(() =>
      `${this.serviceUrl.organization}/api/user`);
  }

  updateUserPersonProfile = (request: UpdateUserPersonProfileRequest) =>
    this.request<UserPersonProfile>(`person`, 'PUT', request);
}
