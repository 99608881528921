import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { G2iAuthService } from 'g2i-ng-auth';
import { ApiGlobalUserService } from 'projects/app-management/src/shared/api/global-user/api-global-user.service';
import { concatMap, map, take, tap } from 'rxjs';
import { ApiOrgUserService } from 'shared/api/organization/org-user/api-org-user.service';
import { ApiUserService } from 'shared/api/organization/user/api-user.service';
import { UserPersonProfileId } from 'shared/constants/id.constants';
import { STATE_MANAGERS } from 'shared/constants/injection-token.constants';
import { ShellStateManagers } from 'shared/store/interfaces';
import { shellState } from 'shared/store/shell.state';
import { manageFields } from 'shared/utils/component.utils';
import { catchHttpErrorsThenStop, pipe, takeWhileTruthy } from 'shared/utils/rxjs.utils';

import { ShellHeaderComponent } from '../shell-header/shell-header.component';
import { UserProfileComponent } from '../user-profile/user-profile.component';

@Component({
  selector: 'app-shell-profile-update',
  templateUrl: './shell-profile-update.component.html',
  styleUrls: ['./shell-profile-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UserProfileComponent, MatButtonModule, ShellHeaderComponent]
})
export class ShellPageProfileUpdateComponent {

  readonly profileToUpdate = this.getProfileToUpdate();
  readonly isGlobal = !this.router.param.organizationid;
  readonly fields = manageFields<ShellPageProfileUpdateComponent>(this);

  constructor(
    @Inject(STATE_MANAGERS)
    readonly stateManagers: ShellStateManagers,
    readonly matSnackBar: MatSnackBar,
    readonly authService: G2iAuthService,
    readonly apiUserService: ApiUserService,
    readonly apiOrgUserService: ApiOrgUserService,
    readonly apiGlobalUserService: ApiGlobalUserService,
    readonly router: Router,
  ) {
    document.title = 'Your profile - Guud';
  }

  onSubmit(userProfileComponent: UserProfileComponent) {
    return pipe(
      concatMap(() => userProfileComponent.formGroup.validate(userProfileComponent)),
      concatMap(() => this.apiUserService.updateUserPersonProfile(userProfileComponent.formGroup.value)),
      catchHttpErrorsThenStop(this),
      takeWhileTruthy(),
      tap(() => shellState.dispatch.refreshSideMenuImage(__filename, !this.stateManagers.state.shell.refreshSideMenuImage)),
      tap(() => this.authService.refreshMeObs().subscribe()),
      tap(() => this.matSnackBar.showSuccess('Profile saved')),
      map(() => true),
    );
  }

  private getProfileToUpdate() {
    return pipe(
      concatMap(() => this.authService.authDetails$),
      take(1),
      concatMap(authDetails =>
        this.isGlobal
          ? this.apiGlobalUserService.getOneUserPersonProfile({ userProfileId: authDetails.user.userPersonProfile.id as UserPersonProfileId })
          : this.apiOrgUserService.getUserPersonProfileByOrganizationAndUserPersonProfile(authDetails.user.userPersonProfile.id as UserPersonProfileId)
            .pipe(map(u => u.userPersonProfile))),
    );
  }

}
