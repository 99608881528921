import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { isDefined } from 'shared/utils/strict-null-check.utils';

import { MEDIA_SERVICE_AUTH_COOKIE_NAME, MEDIA_SERVICE_URL } from './media.constants';
import { getHttpOptions } from './media.functions';
import { DataGetRequest, DataPostRequest, MetaGetRequest, MetaResponse, MetaSaveRequest } from './media.shapes';

@Injectable({ providedIn: 'root' })
export class MediaService {

  constructor(
    @Inject(MEDIA_SERVICE_URL)
    readonly mediaServiceUrl: string,
    @Inject(MEDIA_SERVICE_AUTH_COOKIE_NAME)
    readonly mediaAuthCookieName: string,
    readonly httpClient: HttpClient,
    readonly activatedRoute: ActivatedRoute,
  ) {
  }

  metaGet = ({ id, isGlobal }: MetaGetRequest) => this.httpClient.get<MetaResponse>(
    `${this.mediaServiceUrl}/api/media/${this.orgUrlSegment(isGlobal)}meta/${id}`,
    getHttpOptions(this.mediaAuthCookieName),
  );

  metaPost = ({ isGlobal, ...args }: MetaSaveRequest) => this.httpClient.post<MetaResponse>(
    `${this.mediaServiceUrl}/api/media/${this.orgUrlSegment(isGlobal)}meta`,
    args,
    getHttpOptions(this.mediaAuthCookieName),
  );

  dataGet = ({ isGlobal, id, ...args }: DataGetRequest) => {
    if (args.maxDim === 0) {
      throw new Error('Please style your image to have a width and a height');
    }
    return this.httpClient.get(
      `${this.mediaServiceUrl}/api/media/${this.orgUrlSegment(isGlobal)}data/${id}`,
      {
        params: Object.prune(args) as any as HttpParams,
        responseType: 'blob',
        observe: 'response',
        ...getHttpOptions(this.mediaAuthCookieName),
      })
      .pipe(
        map(data => {
          const body = isDefined(data.body);
          const meta = JSON.parse(isDefined(data.headers.get('x-meta'))) as MetaResponse;
          return {
            meta,
            url: window.URL.createObjectURL(new Blob([body], { type: meta.mimeType })),
          };
        }),
      );
  };

  dataPost = ({ isGlobal, ...args }: DataPostRequest) => {
    const data = new FormData();
    data.append('file', args.blob, args.filename);
    return this.httpClient.post<MetaResponse>(
      `${this.mediaServiceUrl}/api/media/${this.orgUrlSegment(isGlobal)}data/`,
      data,
      getHttpOptions(this.mediaAuthCookieName),
    );
  };

  orgUrlSegment = (isGlobal: boolean) => {
    const organizationId = this.activatedRoute.snapshot.param.organizationid;
    if (!isGlobal && !organizationId) {
      throw new Error(
        'Could not find organizationid in URL. Please pass in [isGlobal]="true" if this media is not org-specific.');
    }
    return isGlobal ? '' : `${organizationId}/`;
  };

}


