import { Observable } from 'rxjs';

export type ValueOf<T> = T[keyof T];

export type HtmlOption<T> = T extends { [key: string]: { id: infer I; name: infer N } } ? { value: I; label: N } : never;
export type LookupId<T> = T extends { [key: string]: { id: infer I } } ? I : never;
export type LookupName<T> = T extends { [key: string]: { name: infer I } } ? I : never;
export type LookupById<T extends { [key: string]: { id: unknown } }, X> =
  T extends { [key: string]: infer I } ? I extends { id: infer W } ? W extends X ? I : never : never : never;
export type LookupByName<T extends { [key: string]: { name: string } }, X> =
  T extends { [key: string]: infer I } ? I extends { name: infer W } ? W extends X ? I : never : never : never;


export type DecisionResult<X, H> = X extends (string | number | boolean | symbol | Record<string, unknown>) ? X : H;

export type UnwrapObservable<X> = X extends Observable<any> ? FunctionParameter<Parameters<X['subscribe']>[0]> : never;

type FunctionParameter<T> = T extends (arg: infer H) => any ? H : never;
type ClassObservables<T> = {
  [I in keyof T]: UnwrapObservable<T[I]>;
};
type SubType<Base, Condition> = Pick<Base, {
  [Key in keyof Base]: Base[Key] extends Condition ? Key : never
}[keyof Base]>;
export type Observables<T> = ClassObservables<SubType<Omit<T, 'fields$'>, Observable<any>>>;

export type ObservableOrNot<T> = T extends Observable<infer O> ? Observable<O> : T;

export type ReverseObservables<T> = Omit<{ [key in keyof T]-?: T[key] extends Observable<infer O> ? O : Observable< NonNullable< T[key] >> }, 'fields'>;

export type ComponentFields<T> = { $: Observable<Observables<T>>; $ready: boolean } & ReverseObservables<T>;

export type Argument<T extends (...args: any) => any> = Parameters<T>[0];

export type Instance<Q> = Q extends new (...args: any[]) => infer T ? T : never;

export abstract class CustomDialog<D, R> { data!: D; result!: R; }

export type LookupUtils = '$htmlOptions' | '$ids' | '$names' | '$values' | '$findById' | '$findByName';
