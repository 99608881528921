import { manageStateSlice } from 'g2i-ngrx-utils';
import { FormDashboardId, UserAccountId } from 'shared/constants/id.constants';

import {
  FormAudience,
  FormDashboard,
  FormRecord,
  FormSave,
  FormSchemaRecord,
  FormSubmissionFindResponse,
  FormSubmissionForAdminResponse,
  SingleChoiceField,
} from '../../../shared/api-gencap/api-gencap.responses';
import { MatTableQuery } from '../../../shared/store/interfaces';

export const formState = manageStateSlice({
  forms: new Array<FormRecord>(),
  schemas: new Array<FormSchemaRecord>(),
  audiences: new Array<FormAudience>(),
  lastUuidThatWasSubmitted: '',
  submissionListQuery: { pageIndex: 0, pageSize: 10, showArchived: false, active: '', direction: 'desc' } as MatTableQuery,
  singleChoiceFieldList: new Array<SingleChoiceField>(),
  dashboardList: new Array<FormDashboard>(),
  timezones: new Array<string>(),
  formSubmissionDetail: {} as FormSubmissionFindResponse,
  formSubmissionsListing: {
    formId: 0,
    scrollPos: 0,
    submittedStartDate: '',
    submittedEndDate: '',
    submittedByUserAccountId: '' as UserAccountId,
    listItems: new Array<FormSubmissionForAdminResponse>(),
  },
})({
  formSubmissionDetail: (state, payload: FormSubmissionFindResponse) => {
    state.formSubmissionDetail = payload;
  },
  formSubmissionsFiltersReset: (state, payload: number) => {
    state.formSubmissionsListing = { ...formState.initialState.formSubmissionsListing, formId: payload };
  },
  formSubmissionListItems: (state, payload: FormSubmissionForAdminResponse[]) => {
    state.formSubmissionsListing.listItems = payload;
  },
  formSubmissionsScroll: (state, payload: number) => {
    state.formSubmissionsListing.scrollPos = payload;
  },
  formSubmissionListItemsReplace: (state, payload: FormSubmissionForAdminResponse[]) => {
    state.formSubmissionsListing.listItems = payload;
  },
  formSubmissionListItemsAppend: (state, payload: FormSubmissionForAdminResponse[]) => {
    state.formSubmissionsListing.listItems.push(...payload);
  },
  formSubmissionsFilterStartDate: (state, date: string) => {
    state.formSubmissionsListing.submittedStartDate = date;
  },
  formSubmissionsFilterEndDate: (state, date: string) => {
    state.formSubmissionsListing.submittedEndDate = date;
  },
  formSubmissionsFilterUserAccountId: (state, userAccountId: UserAccountId) => {
    state.formSubmissionsListing.submittedByUserAccountId = userAccountId;
  },
  submissionListQuery: (state, payload: Partial<MatTableQuery>) => {
    Object.assign(state.submissionListQuery, payload);
  },
  forms: (state, payload: FormRecord[]) => {
    state.forms = payload;
  },
  schemas: (state, payload: FormSchemaRecord[]) => {
    state.schemas = payload;
  },
  formAudiences: (state, payload: FormAudience[]) => {
    state.audiences = payload;
  },
  formArchive: (state, payload: FormRecord) => {
    state.forms[state.forms.findIndex(f => f.id === payload.id)] = payload;
  },
  formCreate: (state, payload: FormSave) => {
    state.forms.push(payload.form);
    state.schemas.push(payload.latestSchema);
  },
  formUpdate: (state, payload: FormSave) => {
    state.forms[state.forms.findIndex(a => a.id === payload.form.id)] = payload.form;
    state.schemas = [...state.schemas.filter(s => s.formId !== payload.form.id), payload.latestSchema];
    if (payload.latestPublishedSchema && (payload.latestPublishedSchema.id !== payload.latestSchema.id)) {
      state.schemas.push(payload.latestPublishedSchema);
    }
  },
  formAudiencesUpdate: (state, payload: { formId: number; formAudiences: FormAudience[] }) => {
    state.audiences = [...state.audiences.filter(a => a.formId !== payload.formId), ...payload.formAudiences];
  },
  singleChoiceFieldList: (state, payload: SingleChoiceField[]) => {
    state.singleChoiceFieldList = payload;
  },
  dashboardList: (state, payload: FormDashboard[]) => {
    state.dashboardList = payload;
  },
  timezones: (state, payload: string[]) => {
    state.timezones = payload;
  },
  dashboardListAppend: (state, payload: FormDashboard) => {
    state.dashboardList.push(payload);
  },
  dashboardListUpdate: (state, payload: FormDashboard) => {
    state.dashboardList[state.dashboardList.findIndex(s => s.id === payload.id)] = payload;
  },
  dashboardRemove: (state, payload: FormDashboardId) => {
    state.dashboardList.remove(e => e.id === payload);
  },
});
