import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';


@Component({
  selector: 'app-media-loader',
  templateUrl: './media-loader.component.html',
  styleUrls: ['./media-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class MediaLoaderComponent {

  constructor(
    readonly elementRef: ElementRef<HTMLElement>
  ) {
  }

}
