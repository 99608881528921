import { defineLookup } from 'shared/utils/lookup.utils';

export const apps = defineLookup({
  assetPartner: {
    id: 101,
    name: 'Asset partner',
    url: 'assets.guudapp.co',
  },
  implementationPartner: {
    id: 102,
    name: 'Implementation partner',
    url: 'schedules.guudapp.co',
  },
  fyne: {
    id: 121,
    name: 'Fyne',
    url: 'app.fyneforms.co',
  },
} as const);

export const geofenceEventTypes = defineLookup({
  depotEnter: {
    id: 1,
    name: 'Depot Enter',
  },
  depotExit: {
    id: 2,
    name: 'Depot Exit',
  },
  serviceBlockEnter: {
    id: 3,
    name: 'ServiceBlock Enter',
  },
  serviceBlockExit: {
    id: 4,
    name: 'ServiceBlock Exit',
  },
} as const);

export const serviceBlockStatuses = defineLookup({
  toAction: {
    id: 1,
    name: 'To action',
    color: '#ECB52B',
    ordinal: 1,
  },
  schedule: {
    id: 2,
    name: 'Scheduled',
    color: '#043A7D',
    ordinal: 2,
  },
  inProgress: {
    id: 3,
    name: 'In progress',
    color: '#00B4D8',
    ordinal: 3,
  },
  new: {
    id: 4,
    name: 'New',
    color: '#ECB52B',
    ordinal: 0,
  },
  cancelled: {
    id: 98,
    name: 'Cancelled',
    color: '#B8B8B8',
    ordinal: 5,
  },
  completed: {
    id: 99,
    name: 'Completed',
    color: '#06B88A',
    ordinal: 4,
  },
} as const);

export const scheduleStatuses = defineLookup({
  unready: {
    id: 1,
    name: 'Unready',
    color: '#ECB52B',
    colorDark: '#b78c21',
    colorLight: '#fbf0d5',
    ordinal: 1,
  },
  ready: {
    id: 2,
    name: 'Ready',
    color: '#043A7D',
    colorDark: '#011c3e',
    colorLight: '#b5d5fd',
    ordinal: 2,
  },
  active: {
    id: 3,
    name: 'Active',
    color: '#00B4D8',
    colorDark: '#0199b7',
    colorLight: '#c4f5ff',
    ordinal: 3,
  },
  completed: {
    id: 4,
    name: 'Completed',
    color: '#06B88A',
    colorDark: '#049972',
    colorLight: '#c1fded',
    ordinal: 4,
  },
  cancelled: {
    id: 5,
    name: 'Cancelled',
    color: '#B8B8B8',
    colorDark: '#b9b6b6',
    colorLight: '#f1f1f1',
    ordinal: 5,
  },
} as const);

export const jobStatus = defineLookup({
  acknowledged: {
    id: 1,
    name: 'Acknowledged'
  },
  declined: {
    id: 2,
    name: 'Declined'
  },
  pendingAcknowledgement: {
    id: 3,
    name: 'Pending acknowledgement'
  },
  picked: {
    id: 4,
    name: 'Picked'
  },
  completed: {
    id: 99,
    name: 'Completed'
  }
} as const);

export const professionalTypes = defineLookup({
  operator: {
    id: 1,
    name: 'Operator'
  },
  optometrist: {
    id: 2,
    name: 'Optometrist'
  },
  nurse: {
    id: 3,
    name: 'Nurse'
  },
  dentalAssistant: {
    id: 4,
    name: 'Dental assistant'
  },
  dentalGeneralPractitioner: {
    id: 5,
    name: 'Dental general practitioner'
  },
  generalPractitioner: {
    id: 6,
    name: 'General practitioner'
  },
  audiologist: {
    id: 7,
    name: 'Audiologist'
  },
  generalProfessional: {
    id: 8,
    name: 'General professional'
  },
} as const);

export const stepCriteria = defineLookup({
  where: {
    id: 1,
    name: 'Where',
    description: 'Where',
  },
  when: {
    id: 2,
    name: 'When',
    description: 'When',
  },
} as const);

export const stepExemptionType = defineLookup({
  whereAutocompleted: {
    id: 12,
    name: 'Where autocompleted',
    description: 'Where autocompleted',
    stepCriteriaId: stepCriteria.where.id,
  },
  whenAutocompleted: {
    id: 13,
    name: 'When autocompleted',
    description: 'When autocompleted',
    stepCriteriaId: stepCriteria.when.id,
  },
} as const);

export const stepExemptionReason = defineLookup({
  whereAutocompleted: {
    id: 100,
    name: 'Where autocompleted',
    stepExemptionTypeId: stepExemptionType.whereAutocompleted.id,
  },
  whenAutocompleted: {
    id: 101,
    name: 'Where autocompleted',
    stepExemptionTypeId: stepExemptionType.whenAutocompleted.id,
  },
} as const);

export const changeRequestResolutions = defineLookup({
  declined: {
    id: 1,
    name: 'Declined',
    color: '#EA4335',
    backgroundColor: '#EA433533',
  },
  accepted: {
    id: 2,
    name: 'Accepted',
    color: '#34AF7A',
    backgroundColor: '#34AF7A2B',
  },
} as const);

export const trackingProviderTypes = defineLookup({
  cartrack: {
    id: 1,
    name: 'Cartrack',
    icon: 'cartrack.png',
  }
} as const);

export const topicTypes = defineLookup({
  project: {
    id: 1,
    name: 'Project',
  },
  schedule: {
    id: 2,
    name: 'Schedule',
  },
  serviceBlock: {
    id: 3,
    name: 'Service block',
  },
  vehicleBooking: {
    id: 4,
    name: 'Vehicle booking',
  },
  job: {
    id: 5,
    name: 'Job',
  },
  mediaReview: {
    id: 6,
    name: 'Media review',
  },
  exemptionReview: {
    id: 7,
    name: 'Exemption review',
  },
} as const);

export const trackerVehicleMatchingStatus = defineLookup({
  newlyMatched: {
    id: 'NEWLY_MATCHED',
    name: 'Newly matched',
    color: '#06B88A',
  },
  alreadyMatched: {
    id: 'ALREADY_MATCHED',
    name: 'Already matched',
    color: '#A8A8A8',
  },
  unmatched: {
    id: 'UNMATCHED',
    name: 'Unmatched',
    color: '#ECB52B',
  }
} as const);

export const organizationTypes = defineLookup({
  assetPartner: {
    id: 1,
    name: 'Asset partner',
  },
  implementationPartner: {
    id: 2,
    name: 'Implementation partner',
  },
  serviceClient: {
    id: 3,
    name: 'Service client',
  },
  dataCollector: {
    id: 4,
    name: 'Data collector',
  },
} as const);

export const applications = defineLookup({
  fyne: {
    id: 121,
    name: 'Fyne',
  },
  assets: {
    id: 101,
    name: 'Assets',
  },
  schedules: {
    id: 102,
    name: 'Schedules',
  },
  reporting: {
    id: 111,
    name: 'Reporting',
  },
  client: {
    id: 103,
    name: 'Client',
  },
} as const);

export const jobStepTypes = defineLookup({
  stepOn: {
    id: 1,
    name: 'Step on',
  },
  startService: {
    id: 2,
    name: 'Start service',
  },
  endService: {
    id: 3,
    name: 'End service',
  },
  stepOff: {
    id: 4,
    name: 'Step off',
  },
} as const);

export const appPermissions = defineLookup({
  backboneOrganizationWrite: {
    id: 1,
    name: 'backbone:organization:write'
  },
  backboneOrganizationRead: {
    id: 2,
    name: 'backbone:organization:read'
  },
  backboneUserWrite: {
    id: 3,
    name: 'backbone:user:write'
  },
  backboneUserRead: {
    id: 4,
    name: 'backbone:user:read'
  },
  organizationSelfWrite: {
    id: 10,
    name: 'organization:self:write'
  },
  organizationUserWrite: {
    id: 13,
    name: 'organization:user:write'
  },
  organizationUserRead: {
    id: 14,
    name: 'organization:user:read'
  },
  placeWrite: {
    id: 20,
    name: 'place:write'
  },
  depotWrite: {
    id: 21,
    name: 'depot:write'
  },
  gencapBackoffice: {
    id: 101,
    name: 'gencap:backoffice'
  },
  gencapMobile: {
    id: 102,
    name: 'gencap:mobile'
  },
  vehicleWrite: {
    id: 110,
    name: 'vehicle:write'
  },
  vehicleGrantWrite: {
    id: 112,
    name: 'vehicle_grant:write'
  },
  scheduleProjectUser: {
    id: 121,
    name: 'schedule:project:user'
  },
  scheduleProjectAdmin: {
    id: 122,
    name: 'schedule:project:admin'
  },
  scheduleServiceblockWriteFull: { // TODO: Remove this permission once it is possible to share projects
    id: 123,
    name: 'schedule:serviceblock:write:full',
  },
  reportingReportWrite: {
    id: 125,
    name: 'reporting:report:write'
  },
} as const);
