import { manageStateSlice } from 'g2i-ngrx-utils';
import {
  OrganizationUserAccount,
  Role,
  UserAccount,
  UserPersonProfile,
} from 'shared/api/organization/api-organization.response';
import { Audience, AudienceMember, AudienceSave } from '../../../shared/api-gencap/api-gencap.responses';

import { MatTableQuery } from '../../../shared/store/interfaces';

export const userAudienceState = manageStateSlice({
  userListQuery: { pageIndex: 0, pageSize: 10, showArchived: false, active: '', direction: 'desc' } as MatTableQuery,
  audienceListQuery: { pageIndex: 0, pageSize: 10, showArchived: false, active: '', direction: 'desc' } as MatTableQuery,
  audiences: new Array<Audience>(),
  audienceMembers: new Array<AudienceMember>(),
  userPersons: new Array<UserPersonProfile>(),
  userAccounts: new Array<UserAccount>(),
  organizationUserAccounts: new Array<OrganizationUserAccount>(),
  roles: new Array<Role>(),
})({
  userListQuery: (state, payload: Partial<MatTableQuery>) => {
    Object.assign(state.userListQuery, payload);
  },
  audienceListQuery: (state, payload: Partial<MatTableQuery>) => {
    Object.assign(state.audienceListQuery, payload);
  },
  userPersons: (state, payload: UserPersonProfile[]) => {
    state.userPersons = payload;
  },
  userAccounts: (state, payload: UserAccount[]) => {
    state.userAccounts = payload;
  },
  organizationUserAccounts: (state, payload: OrganizationUserAccount[]) => {
    state.organizationUserAccounts = payload;
  },
  roles: (state, payload: Role[]) => {
    state.roles = payload;
  },
  audiences: (state, payload: Audience[]) => {
    state.audiences = payload;
  },
  audienceMembers: (state, payload: AudienceMember[]) => {
    state.audienceMembers = payload;
  },
  audienceCreate: (state, payload: AudienceSave) => {
    state.audiences.push(payload.audience);
    state.audienceMembers.push(...payload.audienceMembers);
  },
  audienceUpdate: (state, payload: AudienceSave) => {
    state.audiences[state.audiences.findIndex(a => a.id === payload.audience.id)] = payload.audience;
    state.audienceMembers = [...state.audienceMembers.filter(m => m.audienceId !== payload.audience.id), ...payload.audienceMembers];
  },
  audienceArchive: (state, payload: Audience) => {
    state.audiences[state.audiences.findIndex(a => a.id === payload.id)] = payload;
  },
});
