import { InjectionToken } from '@angular/core';
import { ShellStateManagers } from 'shared/store/interfaces';

export const PERMISSION_REQUIRED_FOR_APP_ACCESS = new InjectionToken<string>('PERMISSION_REQUIRED_FOR_APP_ACCESS');
export const STATE_MANAGERS = new InjectionToken<ShellStateManagers>('STATE_MANAGERS');
export const HEADER_COLOR = new InjectionToken<'primary' | 'accent' | 'warn'>('HEADER_COLOR');
export const APP_ENVIRONMENT_BASE_URL = new InjectionToken<string>('APP_ENVIRONMENT_BASE_URL');
export const SUPPORT_ORG_ID_IN_URL = new InjectionToken<boolean>('SUPPORT_ORG_ID_IN_URL');
export const DEVELOPING_ON_LOCALHOST = new InjectionToken<boolean>('DEVELOPING_ON_LOCALHOST');
export const APP_ORG_ADMIN_FLAG = new InjectionToken<boolean>('APP_ORG_ADMIN_FLAG');
