import { Injectable } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';
import { UserPersonProfileId } from 'shared/constants/id.constants';

import { OrganizationUserAccount, OrganizationUserPersonProfile, UserAccount, UserPersonProfile } from '../api-organization.response';
import { GetUserPersonProfileByOrganizationAndUserAccountRequest } from './api-org-user.requests';

@Injectable({ providedIn: 'root' })
export class ApiOrgUserService extends ApiBaseService {

  constructor() {
    super(() => `${this.serviceUrl.organization}/api/organization/${this.routeParam.organizationid}/user`);
  }

  listUserAccountsByOrganization = () =>
    this.request<UserAccount[]>('account', 'GET');
  listOrganizationUserAccounts = () =>
    this.request<OrganizationUserAccount[]>('org_account', 'GET');
  getUserPersonProfileByOrganizationAndUserAccount = ({ userAccountId }: GetUserPersonProfileByOrganizationAndUserAccountRequest) =>
    this.request<OrganizationUserPersonProfile>(`person/user_account/${userAccountId}`, 'GET');
  listUserPersonProfilesByOrganization = () =>
    this.request<UserPersonProfile[]>(`person`, 'GET');
  getUserPersonProfileByOrganizationAndUserPersonProfile = (userPersonProfileId: UserPersonProfileId) =>
    this.request<OrganizationUserPersonProfile>(`person/user_person_profile/${userPersonProfileId}`, 'GET');

}
