import { ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { fileAcceptAvailable, iconMap, maxImageUploadDimension, maxNonImageUploadSize, svgs } from './media.constants';
import { TypeofFileAcceptTypes } from './media.shapes';

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('')
    .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
  return JSON.parse(jsonPayload);
};

export const getHttpOptions = (mediaAuthCookieName: string) => {
  const auth = getCookie(mediaAuthCookieName || 'g2i-auth');
  const sub = parseJwt(auth).sub;
  return {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${auth}`,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'x-bb-sub': sub,
    }
  };
};

export const setPropValuesImmediately = (componentInstance: any, elementRef: ElementRef<HTMLElement>) => {
  Array.from(elementRef.nativeElement.attributes)
    .filter(attribute => componentInstance[attribute.name])
    .forEach(attribute => componentInstance[attribute.name] = attribute.value);
};

export const fileIsAnImage = (mimetype: string) => Object.keys(fileAcceptAvailable.image)
  .some(key => fileAcceptAvailable.image[key as any] === mimetype);

export const getFileInputAcceptAttributeValue = (fileAcceptTypes: TypeofFileAcceptTypes) => {
  const array = Array.isArray(fileAcceptTypes) ? fileAcceptTypes : [fileAcceptTypes];
  return Object.keysTyped(fileAcceptAvailable)
    .filter(ma => array.indexOf(ma) !== -1)
    .map(k => fileAcceptAvailable[k]).join(',');
};

export const fileIsAcceptable = (file: File, fileAcceptTypes: TypeofFileAcceptTypes, snackbar: MatSnackBar) => {
  const fileAccept = (Array.isArray(fileAcceptTypes) ? fileAcceptTypes : [fileAcceptTypes])
    .map(t => fileAcceptAvailable[t]).reduce((prev, curr) => prev.concat(curr), new Array<string>());
  const fileTypeIsAcceptable = !!fileAccept.find(type => file.type === type);
  if (!fileTypeIsAcceptable) {
    snackbar.open('Sorry, this file type is not accepted', 'Not allowed', { duration: 5000 });
    return false;
  }
  if (!fileIsAnImage(file.type) && file.size > maxNonImageUploadSize) {
    snackbar.open(`Sorry, this file cannot be larger than ${maxNonImageUploadSize / 1000000}MB in size`, 'Not allowed', { duration: 5000 });
    return false;
  }
  return true;
};

/**
 * Use canvas to resize image on client before uploading it to the server
 */
export const reduceFileSizeIfItIsAnImage = (file: File): Promise<Blob> => {
  if (!fileIsAnImage(file.type)) {
    return Promise.resolve(file);
  }
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');
  const dataURItoBlob = (dataURI: string) => {
    const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
      atob(dataURI.split(',')[1]) :
      unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) {
      ia[i] = bytes.charCodeAt(i);
    }
    return new Blob([ia], { type: mime });
  };
  const resize = () => {
    let width = image.width;
    let height = image.height;
    if (width <= maxImageUploadDimension && height <= maxImageUploadDimension) {
      return file;
    }
    if (width > height) {
      if (width > maxImageUploadDimension) {
        height *= maxImageUploadDimension / width;
        width = maxImageUploadDimension;
      }
    } else {
      if (height > maxImageUploadDimension) {
        width *= maxImageUploadDimension / height;
        height = maxImageUploadDimension;
      }
    }
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    if (context) {
      context.drawImage(image, 0, 0, width, height);
    }
    const dataUrl = canvas.toDataURL(file.type);
    return dataURItoBlob(dataUrl);
  };
  return new Promise((ok, no) => {
    reader.onload = (readerEvent) => {
      image.onload = () => ok(resize());
      image.src = (readerEvent.target as any).result;
    };
    reader.readAsDataURL(file);
  });
};

export const getIcon = (filename: string) => {
  const pathSplit = filename.split('.');
  const extension = pathSplit[pathSplit.length - 1] as keyof typeof iconMap;
  const svg = svgs[(iconMap[extension] || iconMap['']) as keyof typeof svgs];
  return window.URL.createObjectURL(new Blob([svg], { type: 'image/svg+xml' }));
};

