export const DAY_COUNT = 30;
export const REFRESH_DURATION = 1000 * 60;
export const ANIMATION_DURATION = 1000;
export const CHART_CONFIG = {
  annotationOuterCircleRadius: 12,
  annotationInnerCircleRadius: 6,
  annotationCircleToTextGap: 8,
  marginTop: 40,
  marginBottom: 48,
  marginLeft: 16,
  marginRight: 16,
  barThickness: 8,
  xAxisOffset: 8,
  xAxisTickSpacing: 8,
  tickLength: 8,
  yAxisMinIncrement: 20,
};
