import 'default-passive-events';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  APP_ENVIRONMENT_BASE_URL,
  DEVELOPING_ON_LOCALHOST,
  G2I_AUTH_CONFIG,
  G2iAuthConfiguration,
  OrganizationAppGuard,
} from 'g2i-ng-auth';
import { MEDIA_SERVICE_AUTH_COOKIE_NAME, MEDIA_SERVICE_URL } from 'shared/components/media/media.constants';
import { environmentOverride, providerOverrides } from 'shared/constants/api.constants';
import { HEADER_COLOR, STATE_MANAGERS, SUPPORT_ORG_ID_IN_URL } from 'shared/constants/injection-token.constants';
import { appPermissions, apps } from 'shared/constants/lookup.constants';
import { DateInputModule } from 'shared/modules/date/date-input.module';
import { ErrorService } from 'shared/services/error.service';

import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { environment } from './environments/environment';
import * as develop from './environments/environment.develop';
import * as prod from './environments/environment.prod';
import * as staging from './environments/environment.staging';
import { getReducers, managers, reducerToken } from './shared/store/core';
import { Effects } from './shared/store/effects';

// avoid compilation warnings that these files are not being used
const unusedFiles = [prod, staging, develop];

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    OrganizationAppGuard,
    {
      provide: reducerToken,
      useFactory: getReducers,
    },
    {
      provide: STATE_MANAGERS,
      useValue: managers,
    },
    {
      provide: APP_ENVIRONMENT_BASE_URL,
      useValue: environment.baseApiUrl,
    },
    {
      provide: SUPPORT_ORG_ID_IN_URL,
      useValue: true,
    },
    {
      provide: DEVELOPING_ON_LOCALHOST,
      useValue: environment.isLocalhost,
    },
    {
      provide: HEADER_COLOR,
      useValue: 'primary',
    },
    {
      provide: MEDIA_SERVICE_AUTH_COOKIE_NAME,
      useValue: environment.authCookieName,
    },
    {
      provide: MEDIA_SERVICE_URL,
      useValue: environment.isLocalhost
        ? providerOverrides.media || environmentOverride || environment.baseApiUrl
        : environment.baseApiUrl
    },
    {
      provide: MEDIA_SERVICE_AUTH_COOKIE_NAME,
      useValue: environment.authCookieName,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorService,
    },
    {
      /** @deprecated */
      provide: 'MEDIA_API_URL',
      useValue: environment.isLocalhost
        ? providerOverrides.media || environmentOverride || environment.baseApiUrl
        : environment.baseApiUrl,
    },
    {
      provide: G2I_AUTH_CONFIG,
      useValue: {
        authServiceUrl: environment.isLocalhost
          ? providerOverrides.auth || environmentOverride || environment.baseApiUrl
          : environment.baseApiUrl,
        cookieName: environment.authCookieName as string,
        providers: ['default'],
        debug: false,
        logo: 'assets/fyne-logo.svg',
        loginLogo: 'assets/fyne-logo-login.svg',
        primaryColor: '#232050',
        minimumRequiredPermissions: [appPermissions.gencapBackoffice.name],
        requiredAppIds: [apps.fyne.id],
        noAuthUrls: ['/welcome', '/form-dashboard/:id'], // No authentication on invite urls (otherwise redirects will occur)
        snackbarCallback: (message: string, icon: string, isError: boolean, action?: string) => {
          (this as unknown as { matSnackbar: MatSnackBar }).matSnackbar.show({ status: isError ? 'error' : 'success', message });
        },
      } as G2iAuthConfiguration,
    },
    importProvidersFrom(
      HttpClientModule,
      MatSnackBarModule,
      BrowserModule,
      BrowserAnimationsModule,
      DateInputModule,
      MatDialogModule,
      RouterModule.forRoot(routes),
      StoreModule.forRoot(reducerToken, {
        runtimeChecks: {
          strictActionImmutability: true,
          strictActionSerializability: true,
          strictStateImmutability: true,
          strictStateSerializability: true,
        },
      }),
      StoreDevtoolsModule.instrument({
        name: 'gencap-backoffice',
        maxAge: 25,
      }),
      EffectsModule.forRoot([Effects]),
    ),
  ],
}).catch(err => console.error(err));
