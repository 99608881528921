import { Inject, Injectable, Optional } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import * as dayjs from 'dayjs';
import * as localeData from 'dayjs/plugin/localeData';

@Injectable()
export class AppDateAdapter extends DateAdapter<string> {
  readonly dFormat = 'YYYY-MM-DD';
  private localeData!: {
    firstDayOfWeek: number;
    longMonths: string[];
    shortMonths: string[];
    dates: string[];
    longDaysOfWeek: string[];
    shortDaysOfWeek: string[];
    narrowDaysOfWeek: string[];
  };

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
  ) {
    super();
    this.setLocale(dateLocale || dayjs.locale());
  }

  override setLocale(locale: string) {
    super.setLocale(locale);

    const momentLocaleData = dayjs.localeData();
    this.localeData = {
      firstDayOfWeek: momentLocaleData.firstDayOfWeek(),
      longMonths: momentLocaleData.months(),
      shortMonths: momentLocaleData.monthsShort(),
      dates: this.range(31, (i) => dayjs(this.createDate(2017, 0, i + 1)).format('D')),
      longDaysOfWeek: momentLocaleData.weekdays(),
      shortDaysOfWeek: momentLocaleData.weekdaysShort(),
      narrowDaysOfWeek: momentLocaleData.weekdaysMin(),
    };
  }

  getYear(date: string) {
    return dayjs(date, this.dFormat).year();
  }
  getMonth(date: string) {
    return dayjs(date, this.dFormat).month();
  }
  getDate(date: string) {
    return dayjs(date, this.dFormat).date();
  }
  getDayOfWeek(date: string) {
    return dayjs(date, this.dFormat).day();
  }
  getMonthNames(style: 'long' | 'short' | 'narrow') {
    return style === 'long' ? this.localeData.longMonths : this.localeData.shortMonths;
  }
  getDateNames() {
    return this.localeData.dates;
  }
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    if (style === 'long') {
      return this.localeData.longDaysOfWeek;
    }
    if (style === 'short') {
      return this.localeData.shortDaysOfWeek;
    }
    return this.localeData.narrowDaysOfWeek;
  }
  getYearName(date: string): string {
    return dayjs(date, this.dFormat).format('YYYY');
  }
  getFirstDayOfWeek(): number {
    return this.localeData.firstDayOfWeek;
  }
  getNumDaysInMonth(date: string): number {
    return dayjs(date, this.dFormat).daysInMonth();
  }
  clone(date: string): string {
    return date + '';
  }
  createDate(year: number, month: number, date: number) {
    if (month < 0 || month > 11) {
      throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
    }
    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }
    const monthStr = (month + 1).toString().length === 1 ? '0' + (month + 1) : (month + 1);
    const dateStr = date.toString().length === 1 ? '0' + date : date;
    const result = `${year}-${monthStr}-${dateStr}`;
    if (!dayjs(result, this.dFormat).locale(this.locale).isValid()) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }
    return result;
  }
  today(): string {
    return dayjs().format(this.dFormat);
  }
  parse(value: any, parseFormat: any) {
    return value;
  }
  addCalendarYears(date: string, years: number) {
    return dayjs(date, this.dFormat).add(years, 'years').format(this.dFormat);
  }
  addCalendarMonths(date: string, months: number) {
    return dayjs(date, this.dFormat).add(months, 'months').format(this.dFormat);
  }
  addCalendarDays(date: string, days: number) {
    return dayjs(date, this.dFormat).add(days, 'days').format(this.dFormat);
  }
  toIso8601(date: string) {
    return dayjs(date, this.dFormat).format();
  }
  isDateInstance(obj: any) {
    return obj && dayjs(obj.toString(), this.dFormat).isValid();
  }
  isValid(date: string) {
    return !!date && dayjs(date.toString()).isValid();
  }
  invalid() {
    return '';
  }
  format(date: string, displayFormat: any) {
    return !date ? '' : dayjs(date, this.dFormat).format('YYYY-MM-DD');
  }

  private range<T>(length: number, valueFunction: (index: number) => T): T[] {
    const valuesArray = Array(length);
    for (let i = 0; i < length; i++) {
      valuesArray[i] = valueFunction(i);
    }
    return valuesArray;
  }

}
