import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { G2iAuthService } from 'g2i-ng-auth';
import { G2iMediaSupportServiceOnline } from 'g2i-ng-media-control';
import { combineLatest } from 'rxjs';
import { concatMap, take, tap } from 'rxjs/operators';
import { ApiOrgUserService } from 'shared/api/organization/org-user/api-org-user.service';
import { pipe } from 'shared/utils/rxjs.utils';

import { userAudienceState } from './user-audience/user-audience.state';

@Injectable({ providedIn: 'root' })
export class MainResolver implements Resolve<any> {

  constructor(
    readonly authService: G2iAuthService,
    readonly apiOrgUser: ApiOrgUserService,
  ) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return combineLatest([
      pipe(
        concatMap(() => this.apiOrgUser.listUserPersonProfilesByOrganization()),
        tap(response => userAudienceState.dispatch.userPersons(__filename, response)),
      ),
      pipe(
        concatMap(() => this.authService.authDetails$),
        take(1),
        tap(response => G2iMediaSupportServiceOnline.init({ orgId: route.param.organizationid, token: response.bbToken })),
      ),
    ]);
  }
}
