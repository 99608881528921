import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { BehaviorSubject, combineLatest, concatMap, filter, map, Observable, of, startWith, tap } from 'rxjs';
import { ComponentFields } from 'shared/types/misc.types';

import { pipe } from './rxjs.utils';

/**
 * A standard trackBy function that can be used in any component optimise list item rendering.
 *
 * See https://angular.io/api/core/TrackByFunction for explanation of the `trackBy` function.
 *
 * @example
 * HTML:
 * ```html
 * <div *ngFor="let item of items; trackBy: trackByKey"></div>
 * ```
 * Typescript:
 * ```ts
 * class MyComponent {
 *   trackByKey = trackByKey;
 *   items = [{name: 'one', key: 1},{name: 'two', key: 2}]; // note: all list items must have a 'key' property.
 * }
 * ```
 */
export const trackByKey = (index: number, item: { key: unknown }) => item.key;

export const trackByIndex = (index: number) => index;

export const detectChangesAndIgnoreErrors = (changeDetector: ChangeDetectorRef) => {
  try {
    changeDetector.detectChanges();
  } catch (e) {
    // ignore unavoidable error:
    // ViewDestroyedError: Attempt to use a destroyed view: detectChanges
  }
};

/**
 * Takes a component and returns an object with the same fields, but with some important differences:
 *
 * 1. All ***observable*** fields are now ***non-observable***.
 *
 * 2. All ***non-observable*** fields are now ***observable***.
 *
 * 3. All observable fields are combined into a single observable `$`, for the template to consume.
 *
 * Note: it's important that you wrap your template code inside `fields.$ | async` in order for function to work (see below example).
 *
 * Using this function has the following advantages:
 * * We can read observable values without subscribing to them.
 * * We can observe changes to non-observable fields, eg an `@Input()`.
 * * We can wait for specific ElementRefs to be added to the DOM before performing some action.
 * * The number of `async` pipes required in the template is reduced to one, making for much more manageable and concise HTML.
 * * Subscribing & unsubscribing is offloaded to Angular (via the template) so there is no chance of accidentally creating memory leaks.
 * * No need to append the `shareReplay()` operator because there is, guaranteed to be, only 1 subscriber (Angular).
 *
 * @example
 * <ng-container *ngIf="fields.$ | async; let $;">
 *   <div>Observable 1: {{$.obs1$}}</div>
 *   <div>Observable 2: {{$.obs2$}}</div>
 * </ng-container>
 *
 * class MyComponent {
 *
 *   // Some regular fields
 *   num = 0;
 *   str = '';
 *   // Some observable fields
 *   obs1$ = ...;
 *   obs2$ = ...;
 *   // Instead of handling manual subscriptions (and potentially accidentally causing memory leaks) we can use this technique
 *   readonly effects$ = combineLatest([this.observeSomething(), this.observeSomethingElse()]);
 *   // IMPORTANT: This function must be invoked AFTER all other component fields
 *   readonly fields = manageFields<MyComponent>(this);
 * }
 *
 */
 export const manageFields = <T extends object>(component: T, flag = false) => {
  const keysOfObservableMembers = Object.keysTyped(component)
    .filter(key => component[key] instanceof Observable && !(component[key] instanceof EventEmitter));
  const cache = {} as { [key in keyof T]: unknown };
  let ready = false;
  return new Proxy(component, {
    get: (t: T, prop: keyof T & string) => {
      if (cache[prop] !== undefined) {
        return cache[prop];
      } else if (prop === '$') {
        return (cache as any).$ = pipe(
          concatMap(() => !keysOfObservableMembers.length
            ? of([])
            : combineLatest(keysOfObservableMembers.map(key => (component[key] as any as Observable<any>).pipe(startWith(undefined))))),
          map(observers => {
            const mapResult = {} as { [key in keyof T | '$']: any };
            observers.forEach((obs, idx) => {
              mapResult[keysOfObservableMembers[idx]] = obs;
              cache[keysOfObservableMembers[idx]] = mapResult[keysOfObservableMembers[idx]];
            });
            mapResult.$ = mapResult;
            return mapResult;
          }),
          tap(value => ready = Object.entries(value).filter(([k]) => k !== 'effects$').every(([k, v]) => v !== undefined)),
        );
      } else if (prop === '$ready') {
        return ready;
      } else {
        const subject = new BehaviorSubject(t[prop]);
        Object.defineProperty(t, prop, {
          get: () => subject.getValue(),
          set: (newValue: T[keyof T & string]) => {
            if (newValue !== subject.getValue()) {
              subject.next(newValue);
            }
          }
        });
        return cache[prop] = subject.pipe(filter(e => e !== undefined));
      }
    }
  }) as ComponentFields<T>;
};

