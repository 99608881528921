import { Injectable } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';

import { FormDashboardDetails } from '../api-gencap.responses';
import { FormDashboardFindRequest } from './api-gencap-public.requests';

@Injectable({ providedIn: 'root' })
export class ApiGencapPublicService extends ApiBaseService {

  constructor() {
    super(() => `${this.serviceUrl.gencap}/api/gencap/pub`);
  }

  formDashboardFind = ({ formDashboardId }: FormDashboardFindRequest) =>
    this.request<FormDashboardDetails>(`form-dashboard/${formDashboardId}`, 'GET', {}, { unauthenticated: true });

}
