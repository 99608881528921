import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { concatMap, tap } from 'rxjs/operators';
import { pipe } from 'shared/utils/rxjs.utils';

import { ApiGencapPublicService } from '../../shared/api-gencap/public/api-gencap-public.service';
import { formDashboardState } from './form-dashboard.state';

@Injectable({ providedIn: 'root' })
export class FormDashboardResolver implements Resolve<any> {

  constructor(
    readonly apiGencapPublic: ApiGencapPublicService,
  ) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return pipe(
      concatMap(() => this.apiGencapPublic.formDashboardFind({ formDashboardId: route.params.formDashboardId })),
      tap(response => formDashboardState.dispatch.details(__filename, response)),
      tap(() => {
        const { chartData } = formDashboardState.state;
        formDashboardState.dispatch.selectedFilter(__filename,  { key: 'all', label: 'All' });
        if (chartData.length) {
          formDashboardState.dispatch.selectedDate(__filename, chartData[chartData.length - 1].day);
        }
      })
    );
  }
}
