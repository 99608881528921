import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { G2iAuthService } from 'g2i-ng-auth';
import { combineLatest, concatMap, map, startWith, takeWhile, tap } from 'rxjs';
import { UserPersonProfile } from 'shared/api/organization/api-organization.response';
import { UpdateUserPersonProfileRequest } from 'shared/api/organization/user/api-user.requests';
import { rules } from 'shared/constants/form.constants';
import { MediaId, UserPersonProfileId } from 'shared/constants/id.constants';
import { MediaTriggerDirective } from 'shared/directives/media-trigger.directive';
import { manageFields } from 'shared/utils/component.utils';
import { pipe } from 'shared/utils/rxjs.utils';

import { MediaComponent } from '../media/media.component';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MediaComponent,
    MatInputModule, MatButtonModule, MatSnackBarModule, MediaTriggerDirective]
})
export class UserProfileComponent {

  @Input() emailIsEditable!: boolean;
  @Input() imageInstruction!: string;
  @Input() emailInstruction!: string;
  @Input() basicsInstruction!: string;
  @Input() toUpdate: UserPersonProfile | null = null;
  @Input() imageIsEditable!: boolean;
  @Input() namesAreEditable!: boolean;
  readonly formGroup = this.getFormGroup();
  readonly isCreating$ = this.getIsCreating();
  readonly effects$ = combineLatest([
    this.setUserPersonProfileFromInputIfPossible(),
    this.disableEmailFieldIfRequired(),
    this.prefillEmailIfNecessary(),
    this.disableNameFieldsIfRequired(),
  ]);
  readonly fields = manageFields<UserProfileComponent>(this);

  constructor(
    readonly formBuilder: FormBuilder,
    readonly matSnackBar: MatSnackBar,
    readonly authService: G2iAuthService,
    readonly changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  private getIsCreating() {
    return pipe(
      concatMap(() => combineLatest([
        this.authService.isAuthenticated(),
        this.fields.toUpdate,
      ])),
      map(([authenticated, userPersonProfile]) => !authenticated || !!userPersonProfile),
    );
  }

  private setUserPersonProfileFromInputIfPossible() {
    return pipe(
      concatMap(() => this.fields.toUpdate),
      tap(userPersonProfile => this.formGroup.patchValue(userPersonProfile)),
    );
  }

  private getFormGroup() {
    return this.formBuilder.groupTyped<UpdateUserPersonProfileRequest>({
      id: ['' as UserPersonProfileId, []],
      firstName: ['', [rules.required()]],
      lastName: ['', [rules.required()]],
      email: ['', [rules.required()]],
      imageId: ['' as MediaId, []],
    });
  }

  private disableEmailFieldIfRequired() {
    return pipe(
      concatMap(() => this.fields.emailIsEditable.pipe(startWith(false))),
      tap(editable => {
        if (!editable) {
          this.formGroup.controls.email.disable();
        } else {
          this.formGroup.controls.email.enable();
        }
      }),
    );
  }

  private disableNameFieldsIfRequired() {
    return pipe(
      concatMap(() => this.fields.namesAreEditable.pipe(startWith(false))),
      tap(editable => {
        if (!editable) {
          this.formGroup.controls.firstName.disable();
          this.formGroup.controls.lastName.disable();
        } else {
          this.formGroup.controls.firstName.enable();
          this.formGroup.controls.lastName.enable();
        }
      })
    );
  }

  private prefillEmailIfNecessary() {
    return pipe(
      concatMap(() => this.authService.isAuthenticated()),
      takeWhile(authenticated => !authenticated),
      tap(() => this.formGroup.controls.email.setValue(this.authService.invite.email))
    );
  }

}
