import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofType } from 'g2i-ngrx-utils';
import { map } from 'rxjs/operators';
import { dataState } from '../../app/main/data/data.state';

@Injectable()
export class Effects {

  readonly dataListCacheClear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        dataState.actions.referenceListItemCreate,
        dataState.actions.referenceListItemUpdate,
        dataState.actions.referenceListItemDelete,
      ),
      map(() => dataState.actions.referenceListItemCacheClear.create(__filename)),
    ),
  );

  constructor(
    private actions$: Actions,
  ) {
  }

}
