import { BadRequestError, BadRequestErrors, StandardError } from 'shared/types/api.types';
import { defineLookup } from 'shared/utils/lookup.utils';

export const errorStatuses = defineLookup({
  unknown: {
    id: 0,
    name: 'unknown error',
    description: 'Oops! Something went wrong. Please contact support',
    errorShape: {  } as any,
  },
  badRequest: {
    id: 400,
    name: 'Bad request',
    description: 'An invalid request was received by the server',
    errorShape: {  } as BadRequestError & BadRequestErrors<any>,
  },
  unauthorized: {
    id: 401,
    name: 'Unauthorized',
    description: 'You don\'t have access to view this page',
    errorShape: {  } as StandardError<401>,
  },
  notAllowed: {
    id: 403,
    name: 'Not allowed',
    description: 'You do not have the required permissions',
    errorShape: {  } as StandardError<403>,
  },
  notFound: {
    id: 404,
    name: 'Not found',
    description: 'Could not find what you\'re looking for',
    errorShape: {  } as StandardError<404>,
  },
  notAcceptable: {
    id: 406,
    name: 'Not acceptable',
    description: 'This action is not acceptable',
    errorShape: {  } as StandardError<406>,
  },
  conflict: {
    id: 409,
    name: 'Conflict',
    description: 'A conflict was encountered',
    errorShape: {  } as any,
  },
  serverError: {
    id: 500,
    name: 'Unknown error',
    description: 'Oops! Something went wrong. Please contact support',
    errorShape: {  } as any,
  },
  badGateway: {
    id: 502,
    name: 'Bad gateway',
    description: 'Could not communicate with the server. Please contact support',
    errorShape: {  } as any,
  },
  serviceUnavailable: {
    id: 503,
    name: 'Service unavailable',
    description: 'Could not communicate with the server. Please contact support',
    errorShape: {  } as any,
  },
} as const);
