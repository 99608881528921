export const regexp = {
  email: {
    regex: /(.+)@(.+){2,}\.(.+){2,}/,
    message: 'Please enter a valid email',
  },
  uppercase: {
    regex: /^[A-Z ]+$/,
    message: 'Please enter only uppercase letters',
  },
  uuid: {
    regex: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
    message: 'Please enter a valid UUID',
  },
  coordinates: {
    regex: /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/,
    message: 'Please enter a valid coordinate'
  },
  what3words: {
    regex: /^[a-zA-Z]+\.[a-zA-Z]+\.[a-zA-Z]+$/,
    message: 'Please enter valid what 3 words'
  },
  time: {
    regex: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
    message: 'Please enter a valid time',
  },
} as const;
