import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { G2I_AUTH_CONFIG, G2iAuthConfiguration, G2iAuthService, getUserOrganizationsForApp } from 'g2i-ng-auth';
import { map, take } from 'rxjs/operators';
import { appPermissions } from 'shared/constants/lookup.constants';

@Injectable({ providedIn: 'root' })
export class GencapMobileGuard implements CanActivate {

  constructor(
    @Inject(G2I_AUTH_CONFIG)
    readonly authConfig: G2iAuthConfiguration,
    readonly authService: G2iAuthService,
    readonly router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.authDetails$.pipe(
      take(1),
      map(authDetails => {
        const permissions = getUserOrganizationsForApp(authDetails,
          { ...this.authConfig, minimumRequiredPermissions: [appPermissions.gencapMobile.name] })
          .map(org => org.permissions.map(p => p.name))
          .reduce((prev, curr) => prev.concat(curr), []);
        if (permissions.includes(appPermissions.gencapMobile.name) && !permissions.includes(appPermissions.gencapBackoffice.name)) {
          return this.router.parseUrl('/welcome');
        } else {
          return true;
        }
      }),
    );
  }

}
