<ng-container *ngIf="fields.$ | async; let $;">
  <app-shell-header>
    <ng-container title>Update profile</ng-container>
  </app-shell-header>
  <app-user-profile #profile
    [basicsInstruction]="'Provide your full name.'"
    [imageInstruction]="'Add a photo profile picture for other users to easily identify you.'"
    [emailInstruction]="'Your email address is preset. If it is incorrect please contact your administrator.'"
    [emailIsEditable]="false"
    [imageIsEditable]="true"
    [namesAreEditable]="true"
    [toUpdate]="$.profileToUpdate">
    <ng-container header>
      <div class="page-title">Update your profile</div>
      <button class="submit-button"
        mat-raised-button
        color="primary"
        (click)="onSubmit(profile).subscribe()">
        Save
      </button>
    </ng-container>
  </app-user-profile>
</ng-container>