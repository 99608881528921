import { manageStateSlice } from 'g2i-ngrx-utils';
import { errorStatuses } from 'shared/constants/error-status.constants';

export const shellState = manageStateSlice({
  resolverErrorReported: null as { code: typeof errorStatuses.$ids[0]; message?: string } | null,
  appHasLoaded: false,
  refreshSideMenuImage: true,
  breadcrumbVars: {} as { [key: string]: string },
  disableDefaultLoader: false,
  resolvingUrl: '',
})({
  disableDefaultLoader: (state, payload: boolean) => {
    state.disableDefaultLoader = payload;
  },
  /** Please do not call this action. It is used internally by the app shell */
  appHasLoaded: (state, payload: boolean) => {
    state.appHasLoaded = payload;
  },
  /** Please do not call this action. It is used internally by the app shell */
  refreshSideMenuImage: (state, payload: boolean) => {
    state.refreshSideMenuImage = payload;
  },
  /** Please do not call this action. It is used internally by the app shell */
  breadcrumbVars: (state, payload: { [key: string]: string }) => {
    state.breadcrumbVars = payload;
  },
  /** Please do not call this action. It is used internally by the app shell */
  resolvingUrl: (state, payload: string) => {
    state.resolvingUrl = payload;
  },
});
