import { HtmlOption, LookupById, LookupByName, LookupId, LookupName, ValueOf } from 'shared/types/misc.types';

const valuesTyped = <T extends object>(o: T) => Object.values(o) as Array<ValueOf<T>>;

export const defineLookup = <K extends (number | string), T extends { [key: string]: { id: K; name: string } }>(arg: T) => ({
  /**
   * Returns an array of { value: number | string, label: string } for template controls (eg mat-select) to consume
   */
  $htmlOptions: valuesTyped(arg).map(e => ({ value: e.id, label: e.name })) as HtmlOption<T>[],
  /**
   * Returns an array of possible IDs for this lookup.
   */
  $ids: valuesTyped(arg).map(e => e.id) as LookupId<T>[],
  /**
   * Returns an array of possible names for this lookup
   */
  $names: valuesTyped(arg) as LookupName<T>[],
  /**
   * Returns an array of possible values for this lookup
   */
  $values: valuesTyped(arg),
  /**
   * Attempts to find a lookup entry from the supplied 'id', else returns undefined
   */
  $findById: <X extends LookupId<T>>(id: X) => valuesTyped(arg).findOrThrow(e => e.id === id) as LookupById<T, X>,
  /**
   * Attempts to find a lookup entry from the supplied 'name', else returns undefined
   */
  $findByName: <X extends LookupName<T>>(id: X) => valuesTyped(arg).findOrThrow(e => e.id === id) as LookupByName<T, X>,
  ...arg,
});
