/**
 * If the supplied value is null or undefined, an error is thrown.
 * If the supplied value exists, the same value is returned, but its type is stripped of nullability.
 */
export const isDefined = <T>(arg: T | null | undefined, errorMessage?: string) => {
  if (arg === null || arg === undefined) {
    throw new Error(errorMessage || `Value is ${arg}`);
  }
  return arg;
};
