import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { MediaComponent } from '../components/media/media.component';


@Directive({
  selector: '[appMediaTriggerFor]',
  standalone: true,
})
export class MediaTriggerDirective implements OnInit {

  @Input()
  appMediaTriggerFor!: MediaComponent;

  constructor(
    readonly elementRef: ElementRef<HTMLElement>,
  ) { }

  ngOnInit() {
    if (this.appMediaTriggerFor.mode === 'thumbnail') {
      throw new Error('This trigger will not open the app-media dialog because its [mode] is "thumbnail", Please set a different mode.');
    }
    this.elementRef.nativeElement.addEventListener('click', () => this.appMediaTriggerFor.elementRef.nativeElement.click());
  }

}

