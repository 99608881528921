import { AbstractControlDirective, ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { BehaviorSubject } from 'rxjs';

/**
 * This class helps to eliminate boilerplate associated with creating a custom material form control.
 * For convenience, some documentation for this class has been copied directly from
 * https://material.angular.io/guide/creating-a-custom-form-field-control
 */
export abstract class MaterialFormControlBoilerplate<T> implements ControlValueAccessor, MatFormFieldControl<T> {

  /**
   * Because the <mat-form-field> uses the OnPush change detection strategy,
   * we need to let it know when something happens in the form field control that may require
   * the form field to run change detection. We do this via the stateChanges property.
   * So far the only thing the form field needs to know about is when the value changes.
   * We'll need to emit on the stateChanges stream when that happens,
   * and as we continue flushing out these properties we'll likely find more places we need to emit.
   * We should also make sure to complete stateChanges when our component is destroyed.
   */
  stateChanges = new BehaviorSubject<void>(null as unknown as void);

  /**
   * This property should return the ID of an element in the component's template that we want
   * the <mat-form-field> to associate all of its labels and hints with.
   */
  id = '';

  /**
   * This property allows us to tell the <mat-form-field> what to use as a placeholder.
   * In this example, we'll do the same thing as matInput and <mat-select> and allow the user to specify it via an @Input().
   * Since the value of the placeholder may change over time, we need to make sure
   * to trigger change detection in the parent form field by emitting on the
   * stateChanges stream when the placeholder changes.
   */
  placeholder = '';

  /**
   * This property indicates whether the form field control should be considered to be in a focused state.
   * When it is in a focused state, the form field is displayed with a solid color underline.
   * We also need to remember to emit on the stateChanges when the focused stated changes stream so change detection can happen.
   */
  focused = false;

  /**
   * This property indicates whether the form field control is empty.
   */
  empty = false;

  /**
   * This property is used to indicate whether the label should be in the floating position.
   */
  shouldLabelFloat = false;

  /**
   * This property is used to indicate whether the input is required. <mat-form-field> uses this
   * information to add a required indicator to the placeholder.
   * Again, we'll want to make sure we run change detection if the required state changes.
   */
  required = false;

  /**
   * This property tells the form field when it should be in the disabled state.
   * In addition to reporting the right state to the form field,
   * we need to set the disabled state on the individual inputs that make up our component.
   */
  disabled = false;

  /**
   * This property indicates whether the associated NgControl is in an error state.
   * In this example, we show an error if the input is invalid and our component has been touched.
   */
  errorState = false;

  /**
   * This property allows us to specify a unique string for the type of control in form field.
   * The <mat-form-field> will add a class based on this type that can be used to easily apply
   * special styles to a <mat-form-field> that contains a specific type of control.
   * In this example we'll use example-tel-input as our control type which will result in
   * the form field adding the class mat-form-field-type-example-tel-input.
   */
  controlType?: string | undefined;
  autofilled?: boolean | undefined;
  userAriaDescribedBy?: string | undefined;

  /**
   * This property allows someone to set or get the value of our control.
   * Its type should be the same type we used for the type parameter when we implemented MatFormFieldControl.
   */
  abstract value: T | null;

  /**
   * This property allows the form field control to specify the @angular/forms control that is bound to this component.
   */
  abstract ngControl: NgControl | AbstractControlDirective | null;

  /**
   * This method is used by the <mat-form-field> to set element ids that should be used for
   * the aria-describedby attribute of your control. The ids are controlled through the form
   * field as hints or errors are conditionally displayed and should be reflected in the
   * control's aria-describedby attribute for an improved accessibility experience.
   * The setDescribedByIds method is invoked whenever the control's state changes.
   * Custom controls need to implement this method and update the aria-describedby
   * attribute based on the specified element ids. Below is an example that shows how this can be achieved.
   */
  setDescribedByIds(ids: string[]): void {
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /**
   * This method will be called when the form field is clicked on.
   * It allows your component to hook in and handle that click however it wants.
   * The method has one parameter, the MouseEvent for the click.
   */
  onContainerClick(event: MouseEvent): void {
  }

  /**
   * Will update the value and notify the form control accordingly.
   */
  writeValue(value: T): void {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Should be called when the controls is touched and notifies the form control accordingly
   */
  private onTouched() { };

  /**
   * Should be called when the controls value changes and notifies the form control accordingly
   */
  private onChange = (_: any) => { };

}
