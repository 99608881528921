import { Data, Route } from '@angular/router';
import { authRoutes, G2iAuthRouteData } from 'g2i-ng-auth';

import { ShellPageProfileUpdateComponent } from '../components/shell-profile-update/shell-profile-update.component';

// Potentially needs to change if we use other data values in routes.
export interface AppRouteData {
  isTopLevelRoute?: boolean;
  orgIsChangeable?: boolean;
  breadcrumb?: string;
}
export type AppRoute = Route & { data?: Data & G2iAuthRouteData & AppRouteData; children?: AppRoute[] };

export const shellRoutes: AppRoute[] = [
  ...authRoutes,
];

export const shellMainRoutes: AppRoute[] = [
  {
    path: 'profile',
    component: ShellPageProfileUpdateComponent,
  },
];
