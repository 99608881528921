import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { configure, defaultDispatchTagPreprocessor } from 'g2i-ngrx-utils';
import { ResolverMonitorService } from 'shared/services/resolver-monitor';
import { AppState } from '../shared/store/interfaces';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styles: [` :host { flex: 1; display: flex; } `],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class AppComponent {

  constructor(
    readonly store: Store<AppState>,
    readonly resolverMonitor: ResolverMonitorService, // please do not remove this constructor arg.
    readonly router: Router,
  ) {
    this.ensureCanDeactivateGuardWorksCorrectlyOnBackPress();
    this.configureNgrxUtils();
  }

  private ensureCanDeactivateGuardWorksCorrectlyOnBackPress() {
    // ref: https://github.com/angular/angular/issues/13586#issuecomment-872592435
    this.router.canceledNavigationResolution = 'computed';
  }

  private configureNgrxUtils() {
    configure({
      dispatchFunction: (arg: any) => this.store.dispatch(arg),
      dispatchTagPosition: 'append',
      dispatchTagPreprocessor: defaultDispatchTagPreprocessor,
    });
  }

}
