import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import Cropper from 'cropperjs';
import { BehaviorSubject, from } from 'rxjs';
import { concatMap, delay, tap } from 'rxjs/operators';
import { manageFields } from 'shared/utils/component.utils';
import { pipe, takeWhileTruthy } from 'shared/utils/rxjs.utils';
import { isDefined } from 'shared/utils/strict-null-check.utils';
import { MediaLoaderComponent } from '../media-loader/media-loader.component';

import {
  fileIsAcceptable,
  fileIsAnImage,
  getFileInputAcceptAttributeValue,
  getIcon,
  reduceFileSizeIfItIsAnImage,
} from '../media/media.functions';
import { MediaService } from '../media/media.service';
import { MetaResponse, Modes, TypeofFileAcceptTypes } from '../media/media.shapes';
import { SafePipe } from '../../services/safe.pipe';
import { CustomDialog } from 'shared/types/misc.types';


export interface MediaDialogComponentArgs {
  meta: MetaResponse;
  url: string;
  aspectRatio: number;
  mode: Modes;
  isGlobal: boolean;
  fileAcceptTypes: TypeofFileAcceptTypes;
  multiFileUrls: null | string[];
  multiFileIndex: null | number;
  multiFileOnChange: null | ((meta: MetaResponse & { index: number }) => any);
  singleFileOnChange: null | ((meta: MetaResponse) => any);
}

@Component({
  selector: 'app-media-dialog',
  templateUrl: './media-dialog.component.html',
  styleUrls: ['./media-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, MatInputModule, MatFormFieldModule, MatTooltipModule, MediaLoaderComponent,
    MediaLoaderComponent, SafePipe],
})
export class MediaDialogComponent extends CustomDialog<MediaDialogComponentArgs, MetaResponse & { index: number }> implements OnDestroy {

  @ViewChild('image')
  readonly image: null | ElementRef<HTMLImageElement> = null;
  readonly canEdit = this.data.mode === 'editable' || this.data.mode === 'replaceable' && this.data.meta.mimeType !== 'image/svg+xml';
  readonly canReplace = this.data.mode === 'replaceable';
  readonly isAnImage = fileIsAnImage(this.data.meta.mimeType);
  cropper: null | Cropper = null;
  readonly loading$ = new BehaviorSubject(false);
  readonly editMode$ = new BehaviorSubject(false);
  readonly cropMode$ = new BehaviorSubject(false);
  readonly navIndex$ = new BehaviorSubject(this.data.multiFileIndex);
  readonly urlToDisplay$ = new BehaviorSubject(this.getUrlToDisplay(this.data.url, this.data.meta));
  readonly imageUrlBeforeEdits$ = new BehaviorSubject('');
  readonly urlCopy$ = new BehaviorSubject(this.data.url);
  readonly multiFileUrlsCopy$ = new BehaviorSubject(this.data.multiFileUrls);
  readonly metaCopy$ = new BehaviorSubject(this.data.meta);
  readonly rotationCopy$ = new BehaviorSubject(this.data.meta.rotate || 0);
  readonly fields = manageFields<MediaDialogComponent>(this);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    readonly data: MediaDialogComponentArgs,
    readonly elementRef: ElementRef<HTMLElement>,
    readonly mediaService: MediaService,
    readonly dialogRef: MatDialogRef<MetaResponse>,
    readonly matSnackbar: MatSnackBar,
  ) {
    super();
    this.temporarilyOverrideMatDialogContainerStyling();
  }

  ngOnDestroy() {
    this.revertTemporaryOverridesToMatDialogContainerStyling();
  }

  onClickNav(direction: number) {
    if (this.multiFileUrlsCopy$.value == null || this.navIndex$.value == null) { throw new Error(); }
    if ((direction < 0 && this.navIndex$.value === 0)
      || (direction > 0 && this.navIndex$.value === this.multiFileUrlsCopy$.value.length - 1)) {
      return;
    }
    this.navIndex$.next(this.navIndex$.value + direction);
    pipe(
      tap(() => this.loading$.next(true)),
      concatMap(() => {
        if (this.multiFileUrlsCopy$.value == null || this.navIndex$.value == null) { throw Error(); }
        return this.mediaService.metaGet({
          id: this.multiFileUrlsCopy$.value[this.navIndex$.value],
          isGlobal: this.data.isGlobal,
        });
      }),
      tap(meta => this.metaCopy$.next(meta)),
      concatMap(meta => this.mediaService.dataGet({
        id: meta.id,
        time: meta.dateUpdated,
        disableCropTransforms: !meta.isCropped,
        isGlobal: this.data.isGlobal,
        maxDim: null,
      })),
      tap(response => this.urlToDisplay$.next(this.getUrlToDisplay(response.url, response.meta))),
      tap(() => this.loading$.next(false)),
    ).subscribe();
  }

  onClickImage() {
    const urlToOpen = fileIsAnImage(this.metaCopy$.value.mimeType) ? this.urlToDisplay$.value : this.urlCopy$.value;
    window.open(urlToOpen, this.metaCopy$.value.originalFileName);
  }

  onClickRotate(by: number) {
    isDefined(this.cropper).rotate(by);
    this.rotationCopy$.next(this.rotationCopy$.value + by);
  }

  onClickClose() {
    if (this.editMode$.value) {
      this.exitEditMode();
    } else {
      this.dialogRef.close({ ...this.metaCopy$.value, index: this.navIndex$.value });
    }
  }

  onClickDownload() {
    Object.assign<HTMLAnchorElement, Partial<HTMLAnchorElement>>(document.createElement('a'), {
      href: this.urlCopy$.value,
      target: '_blank',
      download: this.metaCopy$.value.originalFileName,
    }).click();
  }

  onClickCrop() {
    const cropper = isDefined(this.cropper);
    const hasCropInfo = !!Object.keys(cropper.getCropBoxData()).length;
    if (hasCropInfo) {
      this.cropMode$.next(false);
      cropper.clear();
    } else {
      this.cropMode$.next(true);
      cropper.crop();
      const { x, y, width, height, isCropped } = this.metaCopy$.value;
      const data = Object.assign({ scaleX: 1, scaleY: 1 }, isCropped ? { x, y, width, height } : {}) as Cropper.SetDataOptions;
      cropper.setData(data);
    }
  }

  onClickReplace() {
    const fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', getFileInputAcceptAttributeValue(this.data.fileAcceptTypes));
    fileInput.click();
    fileInput.onchange = e => {
      const files = (e.target as HTMLInputElement).files;
      if (!files || !files.length) { return; }
      const file = files[0];
      if (!fileIsAcceptable(file, this.data.fileAcceptTypes, this.matSnackbar)) { return; }
      pipe(
        concatMap(() => from(reduceFileSizeIfItIsAnImage(file))),
        concatMap(blob => this.mediaService.dataPost({ blob, filename: file.name, isGlobal: this.data.isGlobal })),
        takeWhileTruthy(),
        tap(response => this.metaCopy$.next(response)),
        tap(response => this.rotationCopy$.next(response.rotate || 0)),
        concatMap(response => this.mediaService.dataGet({
          id: response.id,
          time: response.dateUpdated,
          isGlobal: this.data.isGlobal,
          disableCropTransforms: null,
          maxDim: null,
        })),
        tap(response => this.urlToDisplay$.next(this.getUrlToDisplay(response.url, response.meta))),
        tap(response => this.urlCopy$.next(response.url)),
        tap(() => this.notifyChangeListeners()),
      ).subscribe();
    };
  }

  onClickEdit() {
    this.imageUrlBeforeEdits$.next(this.urlToDisplay$.value);
    this.editMode$.next(true);
    const { id, x, y, width, height, isCropped, dateUpdated } = this.metaCopy$.value;
    const data = Object.assign({ scaleX: 1, scaleY: 1 }, isCropped ? { x, y, width, height } : {}) as Cropper.Data;
    const initCropper = () => {
      this.cropper = new Cropper(isDefined(this.image).nativeElement, {
        autoCrop: isCropped || false,
        background: false,
        viewMode: 2,
        data,
        aspectRatio: this.data.aspectRatio,
      });
    };
    if (isCropped) {
      this.loading$.next(true);
      pipe(
        concatMap(() => this.mediaService.dataGet({
          id,
          time: dateUpdated,
          disableCropTransforms: true,
          isGlobal: this.data.isGlobal,
          maxDim: null,
        })),
        tap(response => this.urlToDisplay$.next(this.getUrlToDisplay(response.url, response.meta))),
        delay(0),
        tap(() => initCropper()),
        tap(() => this.loading$.next(false)),
      ).subscribe();
    } else {
      initCropper();
    }
  }

  onClickSave() {
    this.loading$.next(true);
    if (!this.cropper) { throw new Error('Expected this.cropper to not be undefined'); }
    const { x, y, width, height } = this.cropper.getData();
    const isCropped = !!Object.keys(this.cropper.getCropBoxData()).length;
    this.cropper.destroy();
    const { id } = this.metaCopy$.value;
    const rotate = this.rotationCopy$.value;
    const data = {
      id,
      isCropped,
      x: Math.round(x),
      y: Math.round(y),
      width: Math.round(width),
      height: Math.round(height),
      rotate,
      isGlobal: this.data.isGlobal,
    };
    pipe(
      concatMap(() => this.mediaService.metaPost(data)),
      tap(meta => this.metaCopy$.next(meta)),
      concatMap(meta => this.mediaService.dataGet({
        id: meta.id,
        time: meta.dateUpdated,
        disableCropTransforms: !meta.isCropped,
        isGlobal: this.data.isGlobal,
        maxDim: null,
      })),
      tap(response => this.urlToDisplay$.next(this.getUrlToDisplay(response.url, response.meta))),
      tap(() => this.editMode$.next(false)),
      tap(() => this.loading$.next(false)),
      tap(() => this.notifyChangeListeners()),
    ).subscribe();
  }

  private notifyChangeListeners() {
    if (this.navIndex$.value == null) {throw new Error(); }
    if (this.data.multiFileOnChange) {
      this.data.multiFileOnChange({ ...this.metaCopy$.value, index: this.navIndex$.value });
      this.multiFileUrlsCopy$.next(Object.assign([], this.multiFileUrlsCopy$.value, { [this.navIndex$.value]: this.metaCopy$.value.id }));
    } else if (this.data.singleFileOnChange) {
      this.data.singleFileOnChange(this.metaCopy$.value);
    }
  }

  private temporarilyOverrideMatDialogContainerStyling() {
    const dialogContainer = document.querySelector('mat-dialog-container') as HTMLElement;
    dialogContainer.style.backgroundColor = '#000c';
    dialogContainer.style.borderRadius = '0';
    dialogContainer.style.padding = '0';
  }

  private revertTemporaryOverridesToMatDialogContainerStyling() {
    const dialogContainer = document.querySelector('mat-dialog-container') as HTMLElement;
    dialogContainer.style.backgroundColor = '';
    dialogContainer.style.borderRadius = '';
    dialogContainer.style.padding = '';
  }

  private exitEditMode() {
    this.cropper?.destroy();
    this.editMode$.next(false);
    this.urlToDisplay$.next(this.imageUrlBeforeEdits$.value);
    this.rotationCopy$.next(0);
  }

  private getUrlToDisplay(url: string, meta: MetaResponse) {
    return fileIsAnImage(meta.mimeType) ? url : getIcon(meta.originalFileName);
  }

}
