import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { concatMap, first, tap } from 'rxjs/operators';

/**
 * Allows one to define route guards to run synchronously
 *
 * The first guard to return 'false' or a redirect url will be accepted as the response.
 * If there are no defined syncGuards or all return 'true', then the SyncGuard will return true.
 *
 * Route should look similar to:
 * canActivate: [SyncGuard],
 *   data: {
 *     syncGuards: [Guard1, Guard2, Guard3],
 * },
 */
@Injectable({ providedIn: 'root' })
export class SyncGuard implements CanActivate {

  constructor(
    public injector: Injector
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return from(route.data.syncGuards).pipe(
      concatMap((value) => {
        const guard = this.injector.get(value);
        const result = guard.canActivate(route, state);
        if (result instanceof Observable) {
          return result;
        } else if (result instanceof Promise) {
          return from(result);
        } else {
          return of(result);
        }
      }),
      first((x) => x === false || x instanceof UrlTree, true),
    );
  }

}
