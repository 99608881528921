import { Injectable } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';
import { OrganizationUserAccount, UserAccount, UserPersonProfile } from 'shared/api/organization/api-organization.response';

import {
  CreateUserPersonProfileAndAccountRequest,
  GetOneUserAccountRequest,
  GetOneUserPersonProfileRequest,
  ListOrganizationUserAccountsByUserAccountRequest,
  SetUserAccountAdminRequest,
  SetUserAccountArchivedRequest,
  UpdateUserPersonProfileAndAccountRequest,
} from './api-global-org.requests';

@Injectable({ providedIn: 'root' })
export class ApiGlobalUserService extends ApiBaseService {

  constructor() {
    super(() => `${this.serviceUrl.organization}/api/global/user/`);
  }

  getOneUserAccount = ({ accountId }: GetOneUserAccountRequest) =>
    this.request<UserAccount>(`account/${accountId}`, 'GET');
  getAllUserAccounts = () =>
    this.request<UserAccount[]>(`account`, 'GET');
  getOneUserPersonProfile = ({ userProfileId }: GetOneUserPersonProfileRequest) =>
    this.request<UserPersonProfile>(`person/${userProfileId}/`, 'GET');
  getAllUserPersonProfiles = () =>
    this.request<UserPersonProfile[]>(`person`, 'GET');
  createUserPersonProfileAndAccount = (request: CreateUserPersonProfileAndAccountRequest) =>
    this.request<UserPersonProfile>(`person`, 'POST', request);
  updateUserPersonProfileAndAccount = (request: UpdateUserPersonProfileAndAccountRequest) =>
    this.request<UserPersonProfile>(`person`, 'PUT', request);
  setUserAccountArchived = ({ userAccountId, archived }: SetUserAccountArchivedRequest) =>
    this.request<UserAccount>(`account/${userAccountId}/archive`, 'PATCH', { archived });
  setUserAccountAdmin = ({ userAccountId, admin }: SetUserAccountAdminRequest) =>
    this.request<UserAccount>(`account/${userAccountId}/admin`, 'PATCH', { admin });
  listOrganizationUserAccountsByUserAccount = ({ userAccountId }: ListOrganizationUserAccountsByUserAccountRequest) =>
    this.request<OrganizationUserAccount[]>(`account/${userAccountId}/organization`, 'GET');

}
